import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  RequestForm,
  Header,
  Button,
  RequestDetail,
  ExternalLayout,
  AvatarAndName,
  CompletionModal,
  DialogBox,
  Drawer,
  Unauthorized,
} from 'components';
import { SubText, Container, fontStyles, fontSize } from 'ui';
import { useParams, Link } from 'react-router-dom';
import { Spinner, Stack, media } from '@tymate/margaret';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { Form } from 'components/Forms';
import { MdSend } from 'react-icons/md';
import { useSearchParams, useApp } from 'hooks';
import { formatInitialRequestValues, formatDate } from 'utils';
import AutoSubmitRequest from 'components/AutoSubmitRequest';
import { CONFIRM_REQUEST } from 'containers/Organizations/Organization/Work/Requests/Request';
import { useBreakpoint } from 'hooks';
import Dotdotdot from 'react-dotdotdot';
import { ArrowRight, ChatDotsFill } from 'react-bootstrap-icons';

const Card = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2.5)};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing()};
`;

const CardLink = styled(Card).attrs({ as: Link, gutterSize: 1 })`
  margin-top: 0;
  display: flex;
  color: inherit;
  text-decoration: none;
`;

const CardTitle = styled.h3`
  ${fontStyles.h3};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
    `}
`;

const CardLinkIcon = styled.div`
  display: flex;
  color: ${({ theme }) => theme.primary};
  align-items: center;
`;

const CardDescription = styled(Dotdotdot).attrs({ clamp: 2 })`
  color: ${({ theme }) => theme.textLighter};
`;

const RequestWrapper = styled.div`
  ${({ gridDisplayed }) =>
    gridDisplayed &&
    css`
      ${media.desktop`
        display: grid;
        grid-gap: ${({ theme }) => theme.spacing(2)};
        grid-template-columns: 1fr 26em;
      `}
    `}
`;

const Trigger = styled.div`
  ${fontSize.h1};
  width: 2em;
  height: 2em;
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
  position: fixed;
  bottom: 65px;
  right: ${({ theme }) => theme.spacing()};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;

  ${media.tablet`
    bottom: ${({ theme }) => theme.spacing()};
  `}
`;

const GET_RECIPIENT_REQUEST = gql`
  query recipientRequest(
    $tenantApiId: Int!
    $apiId: Int!
    $recipientToken: String!
  ) {
    recipientRequest(
      tenantApiId: $tenantApiId
      apiId: $apiId
      recipientToken: $recipientToken
    ) {
      id
      status
      statusMessage
      deadline
      canAnswer {
        value
      }
      canConfirm {
        value
      }
      canComment {
        value
      }
      name
      createdAt
      description
      recipient {
        firstName
        lastName
      }
      requestComments {
        edges {
          node {
            id
            message
            createdAt
            author {
              firstName
              lastName
              avatarUrl
            }
          }
        }
      }
      author {
        id
        firstName
        lastName
        avatarUrl
      }
      documents {
        id
        fileName
        mimeType
        url
      }
      items {
        name
        description
        type
        required
        ... on DocumentRequestItem {
          id
          name
          type
          document {
            id
            fileName
            mimeType
            url
          }
          answer {
            updatedAt
            documents {
              id
              fileName
              mimeType
              url
            }
          }
        }
        ... on AttachmentRequestItem {
          name
          type
          document {
            id
            fileName
            mimeType
            url
            viewed
          }
        }
        ... on SignatureRequestItem {
          id
          name
          type
          isTwoWaySignature
          status
          recipientSignature {
            id
            status
            createdAt
            updatedAt
          }
          senderSignature {
            status
          }
          signedDocument {
            id
            fileName
            mimeType
            url
          }
          document {
            id
            fileName
            mimeType
            url
          }
          answer {
            createdAt
            updatedAt
          }
        }
        ... on PromptRequestItem {
          id
          name
          type
          message
          choices {
            id
            choice
          }
          document {
            id
            fileName
            mimeType
            url
          }
          answer {
            updatedAt
            choice {
              id
              choice
            }
          }
        }
        ... on MessageRequestItem {
          id
          name
          type
          document {
            id
            fileName
            mimeType
            url
          }
          answer {
            message
            updatedAt
          }
        }
        ... on AcknowledgeRequestItem {
          id
          document {
            id
            fileName
            mimeType
            url
          }
          message
          name
          type
          answer {
            acknowledged
            updatedAt
          }
        }
      }
    }
  }
`;

const APPLY_REQUEST_ITEM_ANSWER = gql`
  mutation applyExternalRequestItemAnswer(
    $input: ApplyRequestItemAnswerInput!
  ) {
    applyRequestItemAnswer(input: $input) {
      errors {
        message
        path
      }
      request {
        id
        canAnswer {
          value
        }
        canConfirm {
          value
        }
        items {
          name
          description
          type
          required
          ... on DocumentRequestItem {
            id
            name
            type
            document {
              id
              fileName
              mimeType
              url
            }
            answer {
              updatedAt
              documents {
                fileName
                mimeType
                url
              }
            }
          }
          ... on PromptRequestItem {
            id
            name
            type
            message
            choices {
              id
              choice
            }
            answer {
              updatedAt
              choice {
                id
                choice
              }
            }
          }
          ... on MessageRequestItem {
            id
            name
            type
            answer {
              message
              updatedAt
            }
          }
          ... on AcknowledgeRequestItem {
            id
            document {
              id
              fileName
              mimeType
              url
            }
            message
            name
            type
            answer {
              acknowledged
              updatedAt
            }
          }
        }
      }
    }
  }
`;

const GET_RECIPIENT_REQUESTS = gql`
  query recipientRequests($recipientToken: String!) {
    recipientRequests(recipientToken: $recipientToken) {
      id
      apiId
      tenantId
      name
      description
    }
  }
`;

const CREATE_DOCUMENT_EVENT = gql`
  mutation createDocumentEvent($input: CreateDocumentEventInput!) {
    createDocumentEvent(input: $input) {
      event {
        id
      }
    }
  }
`;

const RequestsCard = ({ recipientRequests, recipientToken, t }) => (
  <Card>
    <CardTitle>{t('other_request_title')}</CardTitle>
    <Stack
      direction="column"
      gutterSize={1}
      alignX="stretch"
      marginTop={1}
      style={{ maxHeight: 400, overflowY: 'auto' }}
    >
      {(recipientRequests ?? []).map(
        ({ name, description, id, apiId, tenantId }) => (
          <div key={id}>
            <CardLink
              to={`/requests/${tenantId}/${apiId}?recipient_token=${recipientToken}`}
            >
              <Stack direction="column" size="full">
                <CardTitle noMargin>{name}</CardTitle>
                <CardDescription>{description}</CardDescription>
              </Stack>
              <CardLinkIcon>
                <ArrowRight size={20} />
              </CardLinkIcon>
            </CardLink>
          </div>
        ),
      )}
    </Stack>
  </Card>
);

const Request = () => {
  const { notify } = useApp();
  const { recipientToken } = useSearchParams();
  const { tenantApiId, apiId } = useParams();
  const { t } = useTranslation('request');
  const breakpoint = useBreakpoint();

  const [isLoadingDocuments, setIsLoadingDocuments] = useState({});
  const [confirmModalIsShown, setConfirmModalIsShown] = useState();
  const [startConfirmation, setStartConfirmation] = useState();

  const { loading, data, refetch, error } = useQuery(GET_RECIPIENT_REQUEST, {
    notifyOnNetworkStatusChange: true,
    variables: {
      tenantApiId: Number(tenantApiId),
      apiId: Number(apiId),
      recipientToken,
    },
  });

  const { data: requestsData, error: error2 } = useQuery(
    GET_RECIPIENT_REQUESTS,
    {
      variables: {
        recipientToken,
      },
    },
  );

  const [applyRequestItemAnswer, { loading: isMutating }] = useMutation(
    APPLY_REQUEST_ITEM_ANSWER,
    {
      // refetchQueries: [
      //   {
      //     query: GET_RECIPIENT_REQUEST,
      //     variables: {
      //       tenantApiId: Number(tenantApiId),
      //       apiId: Number(apiId),
      //       recipientToken,
      //     },
      //   },
      // ],
    },
  );
  const [confirmRequest, { loading: isConfirmingRequest }] = useMutation(
    CONFIRM_REQUEST,
    {
      refetchQueries: [
        {
          query: GET_RECIPIENT_REQUEST,
          variables: {
            tenantApiId: Number(tenantApiId),
            apiId: Number(apiId),
            recipientToken,
          },
        },
      ],
    },
  );

  const [createDocumentEvent] = useMutation(CREATE_DOCUMENT_EVENT);

  const handleConfirmRequest = async () => {
    try {
      await confirmRequest({
        variables: {
          input: { requestId: data?.recipientRequest?.id },
        },
      });
      setConfirmModalIsShown(false);
      notify(t('request_confirmed_success'));
    } catch (err) {
      notify(t('request_confirmed_error'), { type: 'error' });
    }
  };

  const handleApplyItem = async ({ variables }) => {
    if ((variables?.input?.documents ?? []).length > 0) {
      setIsLoadingDocuments({
        ...isLoadingDocuments,
        [variables?.input?.requestItemId]: true,
      });
    }
    try {
      await applyRequestItemAnswer({ variables }).then(request => {
        refetch();
      });
      setIsLoadingDocuments({
        ...isLoadingDocuments,
        [variables?.input?.requestItemId]: false,
      });
    } catch (err) {
      setIsLoadingDocuments({
        ...isLoadingDocuments,
        [variables?.input?.requestItemId]: false,
      });
    }
  };

  const handleDocumentDownload = async document => {
    try {
      await createDocumentEvent({
        variables: {
          input: {
            kind: 'VIEW',
            documentId: document.id,
            requestApiId: apiId,
            recipientToken: recipientToken,
          },
        },
      });
    } catch (err) {}
  };

  const request = data?.recipientRequest;

  const recipientRequests = (requestsData?.recipientRequests ?? []).filter(
    ({ id }) => id !== request?.id,
  );

  const canConfirmRequest =
    request?.status === 'ONGOING' &&
    (request?.items || []).length > 0 &&
    request.items.filter(({ answer }) => !Boolean(answer)).length === 0;

  useEffect(() => {
    if (!canConfirmRequest) {
      return;
    }

    setConfirmModalIsShown(true);
  }, [canConfirmRequest]);

  if (
    error?.networkError?.result?.error?.message === '401 Unauthorized' ||
    error2?.networkError?.result?.error?.message === '401 Unauthorized'
  ) {
    return (
      <ExternalLayout>
        <Unauthorized />
      </ExternalLayout>
    );
  }

  if (loading && !Boolean(request)) {
    return <Spinner />;
  }

  return (
    <>
      <CompletionModal
        isOpen={confirmModalIsShown}
        onRequestClose={() => {
          setConfirmModalIsShown(false);
          setStartConfirmation(true);
        }}
        request={request}
        onConfirmRequest={handleConfirmRequest}
        isConfirmingRequest={isConfirmingRequest}
      />

      <Formik
        enableReinitialize
        initialValues={formatInitialRequestValues(request)}
      >
        <ExternalLayout
          headerChildren={
            <AutoSubmitRequest
              request={request}
              isLoading={isMutating || loading}
              onApplyItem={handleApplyItem}
            />
          }
        >
          {request?.canAnswer.value ? (
            <div>
              <Header
                size={request?.canComment?.value ? null : 'narrow'}
                leftActions={
                  <AvatarAndName
                    user={request?.author}
                    label={t('created_at', {
                      date: formatDate(
                        request?.createdAt || '',
                        'dd MMMM yyyy - HH:mm',
                      ),
                    })}
                  />
                }
                actions={
                  <Stack
                    alignY="center"
                    gutterSize={1}
                    direction={{ default: 'column', tablet: 'row' }}
                  >
                    <Button
                      variant="outline"
                      onClick={() => setConfirmModalIsShown(true)}
                      disabled={!request?.canConfirm?.value || isMutating}
                    >
                      {t('send_informations')}
                    </Button>
                  </Stack>
                }
                mobileActions={
                  request?.canConfirm?.value && (
                    <Button
                      variant="icon"
                      icon={<MdSend size={22} />}
                      onClick={() => setConfirmModalIsShown(true)}
                    />
                  )
                }
              />

              <Container
                size={
                  request?.canComment?.value || recipientRequests.length > 0
                    ? null
                    : 'narrow'
                }
                variant="main"
              >
                <RequestWrapper
                  gridDisplayed={
                    request?.canComment?.value || recipientRequests.length > 0
                  }
                >
                  <div>
                    <Form>
                      <RequestForm
                        request={request}
                        refetchRequest={refetch}
                        onDocumentDownload={handleDocumentDownload}
                        isLoadingDocuments={isLoadingDocuments}
                        signatureAs="recipient"
                        startConfirmation={startConfirmation}
                      />
                    </Form>

                    <Card>
                      <Stack
                        direction={{ default: 'column', tablet: 'row' }}
                        alignX={{ default: 'center', tablet: 'space-between' }}
                        alignY="center"
                        gutterSize={{ default: 1, tablet: 0.25 }}
                      >
                        <div>
                          <CardTitle>{t('request_completed')}</CardTitle>
                          <SubText hasNoMargin>
                            {t('send_informations_explanation')}
                          </SubText>
                        </div>
                        <Button
                          variant="outline"
                          onClick={() => setConfirmModalIsShown(true)}
                          disabled={!request?.canConfirm?.value || isMutating}
                        >
                          {t('send_informations')}
                        </Button>
                      </Stack>
                    </Card>
                  </div>
                  <div>
                    {Boolean(request?.canComment?.value) && (
                      <div>
                        {breakpoint === 'desktop' ? (
                          <DialogBox
                            requestId={request?.id}
                            requestComments={request?.requestComments?.edges}
                            refetchComments={refetch}
                          />
                        ) : (
                          <Drawer
                            trigger={
                              <Trigger>
                                <ChatDotsFill />
                              </Trigger>
                            }
                          >
                            <DialogBox
                              requestId={request?.id}
                              requestComments={request?.requestComments?.edges}
                              refetchComments={refetch}
                            />
                          </Drawer>
                        )}
                      </div>
                    )}
                    {recipientRequests.length > 0 && (
                      <RequestsCard
                        tenantApiId={tenantApiId}
                        recipientToken={recipientToken}
                        recipientRequests={recipientRequests}
                        t={t}
                      />
                    )}
                  </div>
                </RequestWrapper>
              </Container>
            </div>
          ) : (
            <div>
              <Header
                size={request?.canComment?.value ? null : 'narrow'}
                leftActions={
                  <AvatarAndName
                    user={request?.author}
                    label={
                      request?.createdAt
                        ? t('created_at', {
                            date: formatDate(
                              request?.createdAt || '',
                              'dd MMMM yyyy - HH:mm',
                            ),
                          })
                        : null
                    }
                  />
                }
              />
              <Container
                size={
                  request?.canComment?.value || recipientRequests.length > 0
                    ? null
                    : 'narrow'
                }
                variant="main"
              >
                <RequestWrapper
                  gridDisplayed={
                    request?.canComment?.value || recipientRequests.length > 0
                  }
                >
                  <RequestDetail
                    request={request}
                    refetchRequest={refetch}
                    onDocumentDownload={handleDocumentDownload}
                    signatureAs="recipient"
                  />
                  <div>
                    {Boolean(request?.canComment?.value) && (
                      <div>
                        {breakpoint === 'desktop' ? (
                          <DialogBox
                            requestId={request?.id}
                            requestComments={request?.requestComments?.edges}
                            refetchComments={refetch}
                          />
                        ) : (
                          <Drawer
                            trigger={
                              <Trigger>
                                <ChatDotsFill />
                              </Trigger>
                            }
                          >
                            <DialogBox
                              requestId={request?.id}
                              requestComments={request?.requestComments?.edges}
                              refetchComments={refetch}
                            />
                          </Drawer>
                        )}
                      </div>
                    )}
                    {recipientRequests.length > 0 && (
                      <RequestsCard
                        tenantApiId={tenantApiId}
                        recipientToken={recipientToken}
                        recipientRequests={recipientRequests}
                        t={t}
                      />
                    )}
                  </div>
                </RequestWrapper>
              </Container>
            </div>
          )}
        </ExternalLayout>
      </Formik>
    </>
  );
};

export default Request;
