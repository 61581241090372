import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { Header, WorkForm } from 'components';
import { Container, Card } from 'ui';

const CREATE_WORK = gql`
  mutation createWork($input: CreateWorkInput!) {
    createWork(input: $input) {
      work {
        id
        name
        slug
      }
    }
  }
`;

const NewWork = () => {
  const { state } = useLocation();
  const { t } = useTranslation(['new_work', 'work_form']);
  const navigate = useNavigate();
  const [createWork] = useMutation(CREATE_WORK);

  const handleSubmit = async values => {
    try {
      const { data } = await createWork({
        variables: {
          input: {
            tenantId: state.tenantId,
            ...values,
          },
        },
      });

      navigate(`../${data?.createWork?.work?.slug}`);
    } catch (err) {
      console.dir(err);
    }
  };

  return (
    <>
      <Header title={t('new_folder')} containerSize="narrow" />

      <Container variant="main" size="narrow">
        <Card>
          <WorkForm
            t={t}
            onReset={() => navigate(-1)}
            onSubmit={handleSubmit}
          />
        </Card>
      </Container>
    </>
  );
};

export default NewWork;
