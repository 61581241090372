import React from 'react';
import { Routes, Route } from 'react-router-dom';
import OrganizationsList from './OrganizationsList';
import NewOrganization from './NewOrganization';
import Organization from './Organization';

const Organizations = () => (
  <Routes>
    <Route path="" element={<OrganizationsList />} />
    <Route path="new" element={<NewOrganization />} />
    <Route path=":organizationSlug/*" element={<Organization />} />
  </Routes>
);

export default Organizations;
