import React from 'react';
import RequestsList from './RequestsList';
import NewRequest from './NewRequest';
import Request from './Request';
import { Routes, Route } from 'react-router-dom';

const Requests = ({ isTenantOwner }) => (
  <Routes>
    <Route path="new" element={<NewRequest isTenantOwner={isTenantOwner} />} />
    <Route path="new/:formStep" element={<NewRequest isTenantOwner={isTenantOwner} />} />
    <Route path=":requestSlug" element={<Request />} />
    <Route path="" element={<RequestsList />} />
  </Routes>
);
export default Requests;
