import React from 'react';
import styled, { css } from 'styled-components';
import {
  Header,
  ExternalLayout,
  AvatarAndName,
  Unauthorized,
} from 'components';
import { Container } from 'ui';
import { useParams } from 'react-router-dom';
import { Spinner, media } from '@tymate/margaret';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useSearchParams } from 'hooks';
import { formatDate } from 'utils';
import RequestDocumentDetail from 'components/RequestDocumentDetail';

const RequestWrapper = styled.div`
  ${({ gridDisplayed }) =>
    gridDisplayed &&
    css`
      ${media.desktop`
        display: grid;
        grid-gap: ${({ theme }) => theme.spacing(2)};
        grid-template-columns: 1fr 26em;
      `}
    `}
`;

const GET_RECIPIENT_REQUEST = gql`
  query recipientRequest(
    $tenantApiId: Int!
    $apiId: Int!
    $recipientToken: String!
  ) {
    recipientRequest(
      tenantApiId: $tenantApiId
      apiId: $apiId
      recipientToken: $recipientToken
    ) {
      id
      name
      createdAt
      recipient {
        firstName
        lastName
      }
      author {
        id
        firstName
        lastName
        avatarUrl
      }
    }
  }
`;

const GET_RECIPIENT_DOCUMENT = gql`
  query recipientDocument(
    $tenantApiId: Int!
    $documentApiId: Int!
    $token: String!
  ) {
    recipientDocument(
      tenantApiId: $tenantApiId
      documentApiId: $documentApiId
      token: $token
    ) {
      id
      fileName
      url
    }
  }
`;

const Request = () => {
  const { token } = useSearchParams();
  const { tenantApiId, apiId, documentApiId } = useParams();
  const { t } = useTranslation('request');

  const { loading, data, error } = useQuery(GET_RECIPIENT_REQUEST, {
    notifyOnNetworkStatusChange: true,
    variables: {
      tenantApiId: Number(tenantApiId),
      apiId: Number(apiId),
      recipientToken: token,
    },
  });

  const request = data?.recipientRequest;

  const { loadingDocument, data: documentData, error: error2 } = useQuery(
    GET_RECIPIENT_DOCUMENT,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        tenantApiId: Number(tenantApiId),
        documentApiId: Number(documentApiId),
        token: token,
      },
    },
  );

  const document = documentData?.recipientDocument;

  if (
    error?.networkError?.result?.error?.message === '401 Unauthorized' ||
    error2?.networkError?.result?.error?.message === '401 Unauthorized'
  ) {
    return (
      <ExternalLayout>
        <Unauthorized />
      </ExternalLayout>
    );
  }

  if (loading && !Boolean(request)) {
    return <Spinner />;
  }

  return (
    <>
      <ExternalLayout>
        <div>
          <Header
            size={request?.canComment?.value ? null : 'narrow'}
            leftActions={
              <AvatarAndName
                user={request?.author}
                label={
                  request?.createdAt
                    ? t('created_at', {
                        date: formatDate(
                          request?.createdAt || '',
                          'dd MMMM yyyy - HH:mm',
                        ),
                      })
                    : null
                }
              />
            }
            actions={
              <AvatarAndName
                user={request?.recipient}
                variant="withEmail&Role"
                role={t('recipient')}
              />
            }
          />
          <Container variant="main">
            <RequestWrapper>
              <RequestDocumentDetail
                request={request}
                document={document}
                isLoading={loadingDocument}
              />
            </RequestWrapper>
          </Container>
        </div>
      </ExternalLayout>
    </>
  );
};

export default Request;
