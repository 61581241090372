import React, { useState, useRef, useMemo, createRef, useEffect } from 'react';
import styled from 'styled-components';
import { Header, DataTable, Button, Dropzone } from 'components';
import { useApp, useDownload, useUpload } from 'hooks';
import { useTranslation, Trans } from 'react-i18next';
import gql from 'graphql-tag';
import {
  Buttons,
  Modal,
  Stack,
  Dropdown,
  PopoverItem,
  ButtonReset,
} from '@tymate/margaret';
import { useSearchParams, useBreakpoint } from 'hooks';
import { useMutation, useQuery } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { VaultBreadcrumb, MoveEntitiesDropdown } from 'components';
import {
  Container,
  Legend,
  theme,
  PopoverDividerItem,
  PopoverItemButton,
  PopoverMenu as UIPopoverMenu,
} from 'ui';
import { Formik } from 'formik';
import {
  containsInvalidCharacters,
  formatDate,
  generateAvailableName,
  getDocumentIconFromMimeType,
  getOrderVariableFromLocationSearch,
  isFolderPath,
  isPreviewable,
  replaceFolderNameWithAvailableName,
} from 'utils';
import { Form, TextField } from 'components/Forms';
import { FormLabel } from 'ui/forms';
import { last, pickBy, orderBy, camelCase, get } from 'lodash';
import { useDeepCompareEffect } from 'react-use';
import {
  DOWNLOADS_URL,
  FILESIZE_OPTIONS,
  MAX_FILE_SIZE,
} from '../../../../constants';
import fileSize from 'filesize';
import {
  Check2,
  CheckSquareFill,
  DashSquareFill,
  Download,
  Eye,
  FileEarmarkPlus,
  Folder,
  FolderFill,
  FolderPlus,
  FolderSymlink,
  Pencil,
  PlusCircleDotted,
  ShieldLock,
  Square,
  ThreeDots,
  ThreeDotsVertical,
  Trash,
  X,
} from 'react-bootstrap-icons';
import i18n from 'i18n';
import { AuthenticationService } from 'services';

const DocumentsCount = styled(Stack)`
  color: ${({ theme }) => theme.textLighter};
  padding-top: ${({ theme }) => theme.spacing(1)};

  svg {
    color: ${({ theme }) => theme.primary};
  }
`;

const FolderStack = styled(Stack)`
  svg {
    color: ${({ theme }) => theme.textLighter};
  }

  ${({ isFile, theme }) =>
    isFile &&
    `
  svg {
    color: ${theme.primary};
  }
  `}
`;

const Trigger = styled.div`
  color: ${({ theme }) => theme.textLighter};
  width: 28px;
  height: 28px;
  border-radius: 28px;
  text-align: center;
  transition: background 150ms ease;

  :hover {
    background: ${({ theme }) => theme.separatorLighter};
    color: ${({ theme }) => theme.textLight};
  }
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.separator};
  background: ${({ theme }) => theme.separatorLighter};
  padding: 0 ${({ theme }) => theme.spacing(0.25)};
`;

const InputButton = styled(ButtonReset)`
  svg {
    stroke-width: 1px;
    color: ${({ theme }) => theme.textLightRGB};
  }

  ${({ isSecondary, theme }) =>
    isSecondary &&
    `
    svg {
      color: ${theme.secondary};
    }
  `}
`;

const IconButton = styled(Button)`
  width: 40px;
  height: 40px;
  padding: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.textLighter};

  :hover {
    background: ${({ theme }) => theme.separatorLighter};
    color: ${({ theme }) => theme.textLight};
  }
`;

const TextCropper = styled.span`
  --crop-factor-start: clamp(10ch, 30vw, 40ch);
  --crop-factor-end: 2ch;
  white-space: nowrap;
  overflow: hidden;
  width: min-content;
  max-width: calc(var(--crop-factor-start) + var(--crop-factor-end));
  position: relative;
  visibility: hidden;

  &::before,
  &::after {
    visibility: visible;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
  }
  &::before {
    text-overflow: ellipsis;
    content: attr(data-name);
    left: 0;
    width: min-content;
    max-width: var(--crop-factor-start);
  }
  &::after {
    right: 0;
    content: attr(data-extension);
    width: max-content;
  }
`;

const PopoverMenu = styled(UIPopoverMenu)`
  max-height: 240px;
`;

const GET_WORK_FOLDERS = gql`
  query getWorkFolder(
    $slug: String!
    $foldersOrder: FolderOrderCriteria
    $documentsOrder: DocumentOrderCriteria
    $search: String
  ) {
    work(slug: $slug) {
      id
      slug
      documents {
        totalCount
      }
      canAccessVault {
        value
      }
      canManageVault {
        value
      }
      searchFiles(fileName: $search) {
        edges {
          node {
            ... on Document {
              fileName
              mimeType
              id
              url
              updatedAt
              canDelete {
                value
              }
              canRename {
                value
              }
              canDownload {
                value
              }
            }
            ... on Folder {
              name
              id
              updatedAt
              slug
            }
          }
        }
      }
      folder {
        name
        slug
        url
        updatedAt
        id
        descendantFolders(order: $foldersOrder) {
          edges {
            node {
              id
              name
              slug
              url
              updatedAt
              canDelete {
                value
              }
              canRename {
                value
              }
              canDownload {
                value
              }
            }
          }
        }
        documents(order: $documentsOrder) {
          edges {
            node {
              fileName
              mimeType
              id
              url
              updatedAt
              canDelete {
                value
              }
              canRename {
                value
              }
              canDownload {
                value
              }
            }
          }
        }
      }
    }
  }
`;

const GET_WORK_DOCUMENTS_COUNT = gql`
  query getWorkDocumentsCount(
    $slug: String!
    $activityOrder: FeedActivityOrderCriteria
  ) {
    work(slug: $slug) {
      id
      slug
      documents {
        totalCount
      }
      activities(first: 10, order: $activityOrder) {
        edges {
          node {
            verb
            text
            createdAt
            actor {
              firstName
              lastName
              id
            }
            item {
              ... on Request {
                id
                name
                slug
                recipient {
                  id
                  firstName
                  lastName
                }
              }
              ... on Work {
                id
                name
              }
              ... on User {
                id
                lastName
                firstName
                email
              }
            }
          }
        }
      }
    }
  }
`;

const GET_FOLDER = gql`
  query getFolder(
    $slug: String!
    $foldersOrder: FolderOrderCriteria
    $documentsOrder: DocumentOrderCriteria
  ) {
    folder(slug: $slug) {
      id
      slug
      name
      url
      canDownload {
        value
      }
      canDelete {
        value
      }
      descendantFolders(order: $foldersOrder) {
        edges {
          node {
            id
            slug
            name
            url
            updatedAt
            canDelete {
              value
            }
            canRename {
              value
            }
            canDownload {
              value
            }
          }
        }
      }
      documents(order: $documentsOrder) {
        edges {
          node {
            fileName
            mimeType
            fileLength
            id
            url
            updatedAt
            canDelete {
              value
            }
            canRename {
              value
            }
            canDownload {
              value
            }
          }
        }
      }
    }
  }
`;

const CREATE_FOLDER = gql`
  mutation createFolder($input: CreateFolderInput!) {
    createFolder(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

const CREATE_DOCUMENT = gql`
  mutation createDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      document {
        id
        url
        fileName
      }
    }
  }
`;

const RENAME_DOCUMENT = gql`
  mutation renameDocument($input: RenameDocumentInput!) {
    renameDocument(input: $input) {
      document {
        id
        fileName
      }
    }
  }
`;

const UPDATE_FOLDER = gql`
  mutation updateFolder($input: UpdateFolderInput!) {
    updateFolder(input: $input) {
      folder {
        id
        name
        updatedAt
      }
    }
  }
`;

const DELETE_FOLDER = gql`
  mutation deleteFolder($input: DeleteFolderInput!) {
    deleteFolder(input: $input) {
      errors {
        message
        path
      }
      parentFolder {
        id
      }
    }
  }
`;

const DELETE_DOCUMENT = gql`
  mutation deleteDocument($input: DeleteDocumentInput!) {
    deleteDocument(input: $input) {
      errors {
        message
        path
      }
      parentFolder {
        id
      }
    }
  }
`;

const DELETE_ENTITIES = gql`
  mutation deleteEntities($input: DeleteEntitiesInput!) {
    deleteEntities(input: $input) {
      errors {
        message
        path
      }
      parentFolder {
        id
      }
    }
  }
`;

const selectEntities = ({ documents, folders, sort, search, searchFiles }) => {
  const mergedEntities = (Boolean(search)
    ? searchFiles || []
    : folders.concat(documents)
  ).map(({ __typename, node }) => ({
    __typename,
    node: { ...node, name: node.name || node.fileName },
  }));

  if (!Boolean(sort)) {
    return mergedEntities;
  }

  const { direction, column } = getOrderVariableFromLocationSearch(sort);

  return orderBy(
    mergedEntities,
    ({ node }) => get(node, camelCase(column), '').toString().toLowerCase(),
    direction.toLowerCase(),
  );
};

const Vault = ({ rootFolderSlug }) => {
  const inputRef = useRef();
  const dropdownRef = useRef();
  const entitiesDropdownRef = useRef();
  const currentFolderActionsDropdownRef = useRef();
  const triggerFolderUpload = useRef();
  const triggerFileUpload = useRef();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { workSlug } = useParams();
  const { t } = useTranslation(['vault', 'errors']);
  const { notify } = useApp();
  const { sort, search } = useSearchParams();
  const path = useParams()?.['*'];
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 'mobile';
  const { uppy, setTakenDocumentNames, setTakenFolderNames } = useUpload();
  const { download } = useDownload();

  const [updatedItemId, setUpdatedItemId] = useState(null);
  const [updatedItemName, setUpdatedItemName] = useState(null);

  const [shownNewFolderModal, setShownNewFolderModal] = useState(false);

  const [deletedFolder, setDeletedFolder] = useState();
  const [isDeletingFolder, setIsDeletingFolder] = useState();

  const [deletedDocument, setDeletedDocument] = useState();
  const [isDeletingDocument, setIsDeletingDocument] = useState();

  const [entityActionMode, setEntityActionMode] = useState('default');

  const foldersOrder = getOrderVariableFromLocationSearch(sort);
  const documentsOrder = getOrderVariableFromLocationSearch(
    (sort || '').replace('name', 'filename'),
  );

  const [selectionState, setSelectionState] = useState('none');
  const [selectedDocumentsId, setSelectedDocumentsId] = useState([]);
  const [selectedFoldersId, setSelectedFoldersId] = useState([]);
  const [selectedEntitiesId, setSelectedEntitiesId] = useState([]);
  const [showDeleteEntitiesModal, setShowDeleteEntitiesModal] = useState(false);
  const [showDownloadEntitiesModal, setShowDownloadEntitiesModal] = useState(
    false,
  );
  const [isDeletingEntities, setIsDeletingEntities] = useState(false);
  const [isDownloadingEntities, setIsDownloadingEntities] = useState(false);
  const [canDeleteSelectedEntities, setCanDeleteSelectedEntities] = useState(
    false,
  );

  const { data, refetch: refetchData } = useQuery(GET_WORK_FOLDERS, {
    variables: pickBy({
      slug: workSlug,
      search,
    }),
  });
  const currentFolderSlug = Boolean(path)
    ? last((path || '').split('/'))
    : rootFolderSlug;

  const refetchQueries = () => [
    {
      query: GET_FOLDER,
      variables: pickBy({
        slug: currentFolderSlug,
        foldersOrder,
        documentsOrder,
      }),
    },
    {
      query: GET_WORK_FOLDERS,
      variables: pickBy({
        slug: workSlug,
        search,
      }),
    },
    {
      query: GET_WORK_DOCUMENTS_COUNT,
      variables: {
        slug: workSlug,
        activityOrder: { column: 'CREATED_AT', direction: 'DESC' },
      },
    },
  ];

  const { data: folderData, loading, refetch: refetchFolder } = useQuery(
    GET_FOLDER,
    {
      variables: pickBy({
        slug: currentFolderSlug,
        foldersOrder,
        documentsOrder,
      }),
    },
  );

  const [createDocument] = useMutation(CREATE_DOCUMENT, { refetchQueries });
  const [renameDocument] = useMutation(RENAME_DOCUMENT);
  const [deleteDocument] = useMutation(DELETE_DOCUMENT, { refetchQueries });

  const [createFolder] = useMutation(CREATE_FOLDER, { refetchQueries });
  const [updateFolder] = useMutation(UPDATE_FOLDER);
  const [deleteFolder] = useMutation(DELETE_FOLDER, { refetchQueries });
  const [deleteEntities] = useMutation(DELETE_ENTITIES, { refetchQueries });

  const folders = useMemo(
    () =>
      [
        Boolean(path) ? { node: { name: '..', slug: '..' } } : null,
        ...(folderData?.folder?.descendantFolders?.edges || []),
      ].filter(Boolean),
    [folderData, path],
  );
  const documents = useMemo(() => folderData?.folder?.documents?.edges || [], [
    folderData,
  ]);
  const searchFiles = data?.work?.searchFiles?.edges || [];
  const currentFolder = folderData?.folder;
  const currentFolderId = currentFolder?.id;

  const canManageVault = data?.work?.canManageVault?.value;

  const entities = selectEntities({
    folders,
    documents,
    search,
    sort,
    searchFiles,
  });

  const handleSelectAll = () => {
    console.log('FIXME: select all', entities);
    const selectableDocumentsId = entities
      .filter(
        selectableEntity =>
          selectableEntity.node.id &&
          selectableEntity.node.__typename === 'Document' &&
          selectableEntity?.node?.canDownload?.value,
      )
      .reduce(
        (selectableIds, selectableDocument) => [
          ...selectableIds,
          selectableDocument.node.id,
        ],
        [],
      );
    const selectableFoldersId = entities
      .filter(
        selectableEntity =>
          selectableEntity.node.id &&
          selectableEntity.node.__typename === 'Folder' &&
          selectableEntity?.node?.canDownload?.value,
      )
      .reduce(
        (selectableIds, selectableFolder) => [
          ...selectableIds,
          selectableFolder.node.id,
        ],
        [],
      );

    setSelectedFoldersId(selectableFoldersId);
    setSelectedDocumentsId(selectableDocumentsId);
  };
  const handleClearSelection = () => {
    setSelectedFoldersId([]);
    setSelectedDocumentsId([]);
  };
  const dropdownsRef = useMemo(
    () =>
      Array(entities.length)
        .fill(0)
        .map(() => createRef()),
    [entities.length],
  );

  useEffect(() => {
    uppy.on('upload-success', () => {
      refetchData();
    });
    return () => {
      uppy.off('upload-success');
    };
  }, [uppy, refetchData]);

  useEffect(() => {
    setSelectedEntitiesId([...selectedDocumentsId, ...selectedFoldersId]);
  }, [selectedDocumentsId, selectedFoldersId]);

  useEffect(() => {
    if (selectedEntitiesId.length === 0) {
      setCanDeleteSelectedEntities(false);
      return null;
    }
    let canDeleteEntities = Boolean(
      entities
        .filter(({ node }) => {
          return selectedEntitiesId.includes(node.id);
        })
        .map(({ node }) => Boolean(node?.canDelete?.value))
        .every(canDelete => canDelete === true),
    );
    setCanDeleteSelectedEntities(canDeleteEntities);
  }, [selectedEntitiesId, entities]);

  useEffect(() => {
    setSelectedFoldersId([]);
    setSelectedDocumentsId([]);
  }, [pathname, search]);
  const tableData = entities.map(({ node }, index) => ({
    selection: {
      value: node?.id,
      render: () => {
        // if (node.name === '..' || Boolean(search)) {
        if (node.name === '..') {
          return null;
        }
        return (
          <FormLabel
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              margin: 0,
              padding: '0 1rem',
              cursor: 'pointer',
            }}
            onClick={e => e.stopPropagation()}
          >
            <input
              type="checkbox"
              style={{ display: 'none' }}
              checked={selectedEntitiesId.includes(node?.id) === true}
              onChange={e => {
                e.stopPropagation();
                handleDocumentsSelection(e.currentTarget.checked, node);
              }}
            />
            {selectedEntitiesId.includes(node?.id) === true ? (
              <CheckSquareFill
                size={18}
                color={theme.primary}
                style={{ flex: '0 0 22px', marginTop: 3 }}
              />
            ) : (
              <Square
                size={18}
                color={theme.primary}
                style={{ flex: '0 0 22px', marginTop: 3 }}
              />
            )}
          </FormLabel>
        );
      },
    },
    name: {
      value: node?.__typename === 'Document' ? node?.fileName : node.name,
      render: () => (
        <Stack direction="column" size="full">
          <FolderStack
            gutterSize={0.5}
            alignY="center"
            isFile={Boolean(node.__typename === 'Document')}
            size="full"
          >
            {updatedItemId === node.id ? (
              <Pencil size={16} />
            ) : node?.__typename === 'Document' ? (
              getDocumentIconFromMimeType(node?.mimeType)
            ) : (
              <FolderFill />
            )}
            {updatedItemId === node.id ? (
              <Stack
                alignX="space-between"
                direction={{ default: 'column', tablet: 'row' }}
                style={{ flex: '1' }}
              >
                <Input
                  value={updatedItemName}
                  onChange={e => setUpdatedItemName(e.target.value)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') handleRenameItem(node);
                    else if (e.key === 'Escape') setUpdatedItemId(null);
                  }}
                  ref={inputRef}
                  style={{ flex: '1' }}
                />
                <Stack gutterSize={1} style={{ marginLeft: 5 }}>
                  <InputButton onClick={() => setUpdatedItemId(null)}>
                    <X size={24} />
                  </InputButton>
                  <InputButton
                    isSecondary
                    onClick={() => handleRenameItem(node)}
                  >
                    <Check2 size={24} />
                  </InputButton>
                </Stack>
              </Stack>
            ) : (
              <Stack direction="column">
                <TextCropper
                  data-name={
                    node?.__typename === 'Document'
                      ? node?.fileName.split('.').slice(0, -1).join('.')
                      : node.name
                  }
                  data-extension={
                    node?.__typename === 'Document'
                      ? `.${
                          node?.fileName.split('.')[
                            node?.fileName.split('.').length - 1
                          ]
                        }`
                      : ''
                  }
                >
                  {node?.__typename === 'Document' ? node?.fileName : node.name}
                </TextCropper>
                {/* {isMobile && (
                  <Legend size="small">
                    {node?.fileLength &&
                      fileSize(node?.fileLength, FILESIZE_OPTIONS)}
                    {node?.updatedAt && node?.fileLength && ' - '} 
                    {node?.updatedAt &&
                      formatDate(node?.updatedAt, 'd MMMM yyyy - HH:mm')}
                  </Legend>
                )} */}
              </Stack>
            )}
          </FolderStack>
        </Stack>
      ),
    },
    fileLength: {
      render: () => (
        <Legend size="small">
          {node?.fileLength && fileSize(node?.fileLength, FILESIZE_OPTIONS)}
        </Legend>
      ),
    },
    updatedAt: {
      render: () => (
        <Legend size="small">
          {node?.updatedAt &&
            formatDate(node?.updatedAt, 'd MMMM yyyy - HH:mm')}
        </Legend>
      ),
    },
    actions: {
      render: () => {
        if (
          node.name === '..' ||
          Boolean(search) ||
          (!canManageVault && node?.__typename === 'Folder') ||
          (!canManageVault &&
            !node?.canDelete?.value &&
            !node?.canRename?.value)
        ) {
          return null;
        }
        return (
          <Dropdown
            ref={dropdownsRef[index]}
            trigger={
              <Trigger>
                <ThreeDotsVertical />
              </Trigger>
            }
            onToggle={isOpen => {
              setEntityActionMode('default');
              if (isOpen) handleDocumentsSelection(true, node, true);
            }}
          >
            {entityActionMode === 'default' && (
              <PopoverMenu alignment="right">
                {node?.canDownload?.value && node?.url && isPreviewable(node) && (
                  <PopoverItem>
                    <PopoverItemButton
                      onClick={e => {
                        e.preventDefault();

                        const previewUrl = new URL(node?.url);
                        previewUrl.searchParams.append('preview', true);

                        const newWindow = window.open(
                          previewUrl,
                          '_blank',
                          'noopener,noreferrer',
                        );
                        if (newWindow) newWindow.opener = null;
                      }}
                    >
                      <span>
                        <Eye />
                      </span>
                      {t('preview')}
                    </PopoverItemButton>
                  </PopoverItem>
                )}

                {node?.canRename?.value && (
                  <PopoverItem>
                    <PopoverItemButton
                      onClick={e => handleStartRenameItem(e, node, index)}
                    >
                      <span>
                        <Pencil />
                      </span>
                      {t('rename')}
                    </PopoverItemButton>
                  </PopoverItem>
                )}

                {canManageVault && (
                  <PopoverItem>
                    <PopoverItemButton
                      onClick={() => setEntityActionMode('move')}
                    >
                      <span>
                        <FolderSymlink />
                      </span>
                      {t('move')}
                    </PopoverItemButton>
                  </PopoverItem>
                )}
                {node?.canDownload?.value && node?.url && (
                  <PopoverItem>
                    <PopoverItemButton
                      onClick={e => {
                        e.preventDefault();
                        download(node);
                        dropdownsRef[index].current.close();
                      }}
                    >
                      <span>
                        <Download />
                      </span>
                      {t('download')}
                    </PopoverItemButton>
                  </PopoverItem>
                )}
                {node?.canDelete?.value && (
                  <PopoverItem>
                    <PopoverItemButton
                      onClick={e => {
                        e.preventDefault();
                        if (node?.__typename === 'Document') {
                          setDeletedDocument(node);
                        } else {
                          setDeletedFolder(node);
                        }
                      }}
                    >
                      <span>
                        <Trash />
                      </span>
                      {t('delete')}
                    </PopoverItemButton>
                  </PopoverItem>
                )}
              </PopoverMenu>
            )}

            {entityActionMode === 'move' && (
              <MoveEntitiesDropdown
                initialFolderSlug={currentFolderSlug}
                documentIds={selectedDocumentsId}
                folderIds={selectedFoldersId}
                originParentFolderId={
                  Boolean(currentFolderId)
                    ? currentFolderId
                    : data?.work?.folder?.id
                }
                onMoveSuccess={() => {
                  dropdownsRef[index].current.close();
                  handleClearSelection();
                  refetchFolder();
                }}
              />
            )}
          </Dropdown>
        );
      },
    },
    path:
      updatedItemId !== node.id
        ? node?.__typename === 'Document'
          ? null
          : `${pathname}/${node.slug}`
        : null,
    href:
      updatedItemId !== node.id && node?.__typename === 'Document'
        ? node?.url
        : null,
    onContextMenuEvent: () => dropdownsRef[index].current.open(),
  }));

  const handleDocumentsSelection = (
    addToSelection,
    node,
    singleton = false,
  ) => {
    if (addToSelection || singleton) {
      if (node?.__typename === 'Document') {
        setSelectedDocumentsId(
          singleton ? [node?.id] : [...selectedDocumentsId, node?.id],
        );
        singleton && setSelectedFoldersId([]);
      } else if (node?.__typename === 'Folder') {
        setSelectedFoldersId(
          singleton ? [node?.id] : [...selectedFoldersId, node?.id],
        );
        singleton && setSelectedDocumentsId([]);
      }
    } else {
      if (node?.__typename === 'Document') {
        setSelectedDocumentsId(
          [...selectedDocumentsId].filter(
            documentId => documentId !== node?.id,
          ),
        );
      } else if (node?.__typename === 'Folder') {
        setSelectedFoldersId(
          [...selectedFoldersId].filter(folderId => folderId !== node?.id),
        );
      }
    }
  };
  const handleStartRenameItem = (e, node, index) => {
    e.preventDefault();
    setUpdatedItemId(node?.id);
    const getName = () => {
      if (node.__typename === 'Document') {
        return node?.fileName.split('.').slice(0, -1).join('.');
      }
      return node?.name;
    };

    setUpdatedItemName(getName);
    dropdownsRef[index].current.close();
    setTimeout(() => {
      if (!inputRef.current) {
        return;
      }
      inputRef.current.focus();
    });
  };

  const handleCreateFolder = async (values, formikBag) => {
    try {
      const { data: resultData } = await createFolder({
        variables: {
          input: pickBy({
            ...values,
            parentFolderId: Boolean(currentFolderId)
              ? currentFolderId
              : data?.work?.folder?.id,
          }),
        },
      });

      if ((resultData?.createFolder?.errors || []).length > 0) {
        formikBag.setFieldError('name', t('errors:folder_name_already_taken'));
      } else {
        setShownNewFolderModal(false);
      }
    } catch (err) {
      // console.dir(err);
    }
  };

  const handleAddDocuments = async files => {
    const maxSize = MAX_FILE_SIZE;
    let invalidFilenames = false;
    const takenDocumentNames = documents.map(
      ({ node: { fileName } }) => fileName,
    );
    const takenFolderNames = folders.map(({ node: { name } }) => name);

    try {
      await Promise.all(
        Array.from(files)
          .map(file => {
            if (file.size > maxSize) {
              notify(
                t('errors:file_too_big', {
                  size: fileSize(maxSize, {
                    ...FILESIZE_OPTIONS,
                    round: 2,
                    locale: i18n.language,
                  }),
                }),
                { type: 'error' },
              );
              return null;
            } else {
              var cleanPath = file.path;
              invalidFilenames = containsInvalidCharacters(file?.name)
                ? true
                : invalidFilenames;
              if (isFolderPath(cleanPath)) {
                cleanPath = replaceFolderNameWithAvailableName({
                  path: cleanPath,
                  takenNames: takenFolderNames,
                });
              }

              const newPath = generateAvailableName({
                fileName: cleanPath, // Send whole file path to look for existing names in the same folder only
                takenNames: takenDocumentNames,
              });
              const newFile = new File([file], newPath, { type: file.type });

              return createDocument({
                variables: {
                  input: {
                    file: newFile,
                    filePath: newPath,
                    folderId: Boolean(currentFolderId)
                      ? currentFolderId
                      : data?.work?.folder?.id,
                  },
                },
              });
            }
          })
          .filter(Boolean),
      );
      // Triggers if filenames have been renamed
      if (invalidFilenames) {
        notify(t('vault:invalid_filename_renamed'));
      }
    } catch (err) {
      // Triggers when filenames haven't been renamed by the server
      if (invalidFilenames) {
        notify(t('errors:invalid_filename'), { type: 'error' });
      }
    }
  };

  const headings = [
    {
      slug: 'selection',
      label: '',
      cannotBeReordered: true,
      alignX: 'flex-end',
      paddingLeft: 0,
      paddingRight: 0,
    },
    {
      slug: 'name',
      label: t('name'),
      size: '1fr',
    },
    {
      slug: 'fileLength',
      label: t('file_length'),
      alignX: 'flex-end',
      isHidden: isMobile,
    },
    {
      slug: 'updatedAt',
      label: t('updated_at'),
      alignX: 'flex-end',
      isHidden: isMobile,
    },
    {
      slug: 'actions',
      label: '',
      cannotBeReordered: true,
      paddingLeft: 0,
      alignY: 'center',
    },
  ];
  const handleDeleteEntities = async () => {
    setIsDeletingEntities(true);
    try {
      await deleteEntities({
        variables: {
          input: {
            parentFolderId: Boolean(currentFolderId)
              ? currentFolderId
              : data?.work?.folder?.id,
            documentIds: selectedDocumentsId,
            folderIds: selectedFoldersId,
          },
        },
      });
      setShowDeleteEntitiesModal(false);
      handleClearSelection();
    } catch (error) {
      console.log(error);
    }
    setIsDeletingEntities(false);
  };
  const handleDownloadEntities = async () => {
    // unselect entities
    handleClearSelection();
    setIsDownloadingEntities(true);
    try {
      const selectedDocumentsLength = documents
        .filter(({ node }) => selectedDocumentsId.includes(node.id))
        .reduce((sum, { node }) => sum + (node.fileLength ?? 0), 0);
      const selectedFoldersLength = folders
        .filter(({ node }) => selectedFoldersId.includes(node.id))
        .reduce((sum, { node }) => sum + (node.fileLength ?? 0), 0);

      const newFile = {
        url: DOWNLOADS_URL,
        name: currentFolder.name,
        fileName: `${currentFolder.name}.zip`,
        fileLength: selectedDocumentsLength + selectedFoldersLength,
        downloadMethod: 'post',
        downloadData: {
          document_ids: selectedDocumentsId,
          folder_ids: selectedFoldersId,
          parent_folder_id: Boolean(currentFolderId)
            ? currentFolderId
            : data?.work?.folder?.id,
        },
        downloadHeaders: {
          Authorization: `Bearer ${AuthenticationService.instance.keycloak.token}`,
        },
      };
      download(newFile);
    } catch (error) {
      console.log(error);
    }
    setShowDownloadEntitiesModal(false);
    setIsDownloadingEntities(false);
  };
  const handleDeleteFolder = async () => {
    setIsDeletingFolder(true);
    try {
      await deleteFolder({
        variables: {
          input: {
            folderId: deletedFolder.id,
          },
        },
        refetchQueries:
          currentFolderId === deletedFolder.id ? [] : refetchQueries,
      });
      setDeletedFolder(null);
      handleClearSelection();
      currentFolderId === deletedFolder.id && navigate(`${pathname}/..`);
    } catch (err) {}
    setIsDeletingFolder(false);
  };

  const handleDeleteDocument = async () => {
    setIsDeletingDocument(true);
    try {
      await deleteDocument({
        variables: {
          input: {
            documentId: deletedDocument.id,
          },
        },
      });
      setDeletedDocument(null);
      handleClearSelection();
    } catch (err) {}
    setIsDeletingDocument(false);
  };

  const handleRenameItem = async node => {
    try {
      if (Boolean(node.__typename === 'Document')) {
        const foo = node?.fileName.split('.');
        const extension = `.${foo[foo.length - 1]}`;
        let filename = `${updatedItemName}${extension}`;
        if (!containsInvalidCharacters(filename)) {
          await renameDocument({
            variables: {
              input: {
                documentId: node.id,
                filename: filename,
              },
            },
          });
        } else {
          notify(t('errors:invalid_filename'), { type: 'error' });
        }
      } else {
        await updateFolder({
          variables: {
            input: {
              folderId: node.id,
              name: updatedItemName,
            },
          },
        });
      }
      handleClearSelection();
      setUpdatedItemId(null);
      setUpdatedItemName(null);
    } catch (err) {
      // console.dir(err);
    }
  };

  useDeepCompareEffect(() => {
    setUpdatedItemId(null);
    setUpdatedItemName(null);
    refetchFolder();
  }, [{ currentFolderSlug }]);

  useEffect(() => {
    const takenDocumentNames = documents.map(
      ({ node: { fileName } }) => fileName,
    );
    const takenFolderNames = folders.map(({ node: { name } }) => name);

    setTakenDocumentNames(takenDocumentNames);
    setTakenFolderNames(takenFolderNames);
  }, [documents, folders, setTakenDocumentNames, setTakenFolderNames]);

  useEffect(() => {
    if (!entities) return null;
    let newSelectionState =
      selectedEntitiesId.length === 0
        ? 'none'
        : selectedEntitiesId.length ===
          entities.filter(entity => entity.node.id).length
        ? 'all'
        : 'mixed';
    setSelectionState(newSelectionState);
  }, [selectedEntitiesId, entities]);
  const displayCurrentFolderActions = () => {
    return (
      (currentFolder?.canDownload?.value && currentFolder?.url) ||
      currentFolder?.canDelete?.value
    );
  };

  return (
    <>
      <Header shouldShowChildrenOnly>
        <VaultBreadcrumb />
      </Header>

      <Container variant="main">
        <DataTable
          isLoading={loading}
          sortingIsHandledBySearchQuery
          selectedEntitiesId={selectedEntitiesId}
          tableSelections={
            <Stack
              alignY="center"
              gutterSize={isMobile ? 0.5 : 1.5}
              direction={isMobile ? 'column' : 'row'}
              marginLeft={0.5}
            >
              {/* TODO: conditional with search param */}
              {Boolean(!search) &&
                (selectionState === 'none' ? (
                  <IconButton
                    variant="icon"
                    onClick={() => handleSelectAll()}
                    icon={
                      <Square
                        size={18}
                        color={theme.primary}
                        style={{ flex: '0 0 20px' }}
                      />
                    }
                  ></IconButton>
                ) : selectionState === 'all' ? (
                  <IconButton
                    variant="icon"
                    onClick={() => handleClearSelection()}
                    icon={
                      <CheckSquareFill
                        size={18}
                        color={theme.primary}
                        style={{ flex: '0 0 20px' }}
                      />
                    }
                  ></IconButton>
                ) : (
                  <IconButton
                    variant="icon"
                    onClick={() => handleClearSelection()}
                    icon={
                      <DashSquareFill
                        size={18}
                        color={theme.primary}
                        style={{ flex: '0 0 20px' }}
                      />
                    }
                  ></IconButton>
                ))}

              {selectionState !== 'none' && updatedItemId === null && (
                <Stack alignY="center" gutterSize={isMobile ? 1 : 2}>
                  <Button
                    variant="text"
                    onClick={() => setShowDownloadEntitiesModal(true)}
                    icon={<Download />}
                  >
                    {t('download')}
                  </Button>
                  {canDeleteSelectedEntities && (
                    <Button
                      variant="text"
                      onClick={() => setShowDeleteEntitiesModal(true)}
                      icon={<Trash />}
                    >
                      {t('delete')}
                    </Button>
                  )}
                  {canManageVault && (
                    <Dropdown
                      ref={entitiesDropdownRef}
                      trigger={
                        <Button variant="text" icon={<FolderSymlink />}>
                          {t('move')}
                        </Button>
                      }
                    >
                      {
                        <MoveEntitiesDropdown
                          initialFolderSlug={currentFolderSlug}
                          movedEntityId={'node?.id'}
                          documentIds={selectedDocumentsId}
                          folderIds={selectedFoldersId}
                          originParentFolderId={
                            Boolean(currentFolderId)
                              ? currentFolderId
                              : data?.work?.folder?.id
                          }
                          onMoveSuccess={() => {
                            entitiesDropdownRef.current.close();
                            handleClearSelection();
                            refetchFolder();
                          }}
                        />
                      }
                    </Dropdown>
                  )}
                </Stack>
              )}
            </Stack>
          }
          tableActions={
            <Stack
              style={{
                flexDirection: isMobile && 'row-reverse',
                marginLeft: isMobile && 'auto',
              }}
              gutterSize={1}
              alignX={isMobile ? 'flex-end' : 'center'}
              alignY="center"
            >
              {displayCurrentFolderActions() && (
                <Dropdown
                  ref={currentFolderActionsDropdownRef}
                  trigger={
                    <IconButton
                      variant="icon"
                      icon={
                        <ThreeDots
                          style={{ marginRight: 0 }}
                          size={isMobile ? 18 : 24}
                        />
                      }
                      disabled={loading}
                    ></IconButton>
                  }
                >
                  <PopoverMenu alignment="right">
                    {currentFolder?.canDownload?.value && currentFolder?.url && (
                      <PopoverItem>
                        <PopoverItemButton
                          onClick={e => {
                            e.preventDefault();
                            download(currentFolder);
                          }}
                        >
                          <span>
                            <Download />
                          </span>
                          {t('download_folder')}
                        </PopoverItemButton>
                      </PopoverItem>
                    )}

                    {currentFolder?.canDelete?.value && (
                      <PopoverItem>
                        <PopoverItemButton
                          variant="error"
                          onClick={e => {
                            e.preventDefault();
                            setDeletedFolder(currentFolder);
                          }}
                        >
                          <span>
                            <Trash />
                          </span>
                          {t('delete')}
                        </PopoverItemButton>
                      </PopoverItem>
                    )}
                  </PopoverMenu>
                </Dropdown>
              )}

              <Dropdown
                ref={dropdownRef}
                trigger={
                  <Button
                    variant="outline"
                    style={{ backgroundColor: 'white' }}
                    icon={
                      <PlusCircleDotted
                        style={{ marginRight: isMobile ? 4 : 8 }}
                        size={isMobile ? 16 : 18}
                      />
                    }
                    disabled={loading}
                  >
                    {t('add')}
                  </Button>
                }
              >
                <PopoverMenu alignment="right">
                  {canManageVault && (
                    <>
                      <PopoverItem>
                        <PopoverItemButton
                          onClick={e => {
                            e.preventDefault();
                            setShownNewFolderModal(true);
                            if (dropdownRef.current) {
                              dropdownRef.current.close();
                            }
                          }}
                        >
                          <span>
                            <FolderPlus />
                          </span>
                          {t('new_folder')}
                        </PopoverItemButton>
                      </PopoverItem>
                      <PopoverDividerItem />
                    </>
                  )}
                  <PopoverItem>
                    <PopoverItemButton
                      as="label"
                      onClick={() => {
                        triggerFileUpload.current();
                        if (dropdownRef.current) {
                          dropdownRef.current.close();
                        }
                      }}
                    >
                      {/* <input
                        type="file"
                        style={{ display: 'none' }}
                        multiple
                        onChange={e => {
                          onUploadDocuments({
                            folderId: Boolean(currentFolderId)
                              ? currentFolderId
                              : data?.work?.folder?.id,
                            files: [...e.target.files],
                            request: refetchFolder,
                          });
                          if (dropdownRef.current) {
                            dropdownRef.current.close();
                          }
                        }}
                        onClick={e => (e.target.value = null)}
                      /> */}
                      <span>
                        <FileEarmarkPlus />
                      </span>
                      {t('import_files')}
                    </PopoverItemButton>
                  </PopoverItem>

                  <PopoverItem>
                    <PopoverItemButton
                      as="label"
                      onClick={() => {
                        triggerFolderUpload.current();
                        if (dropdownRef.current) {
                          dropdownRef.current.close();
                        }
                      }}
                    >
                      {/* <input
                        type="file"
                        style={{ display: 'none' }}
                        directory=""
                        webkitdirectory=""
                        onChange={e => {
                          onUploadDocuments({
                            folderId: Boolean(currentFolderId)
                              ? currentFolderId
                              : data?.work?.folder?.id,
                            files: [...e.target.files],
                            request: refetchFolder,
                          });
                          if (dropdownRef.current) {
                            dropdownRef.current.close();
                          }
                        }}
                        onClick={e => (e.target.value = null)}
                      /> */}
                      <span>
                        <Folder />
                      </span>
                      {t('import_folder')}
                    </PopoverItemButton>
                  </PopoverItem>
                </PopoverMenu>
              </Dropdown>
            </Stack>
          }
          headings={headings}
          data={tableData}
          tableBottom={
            !Boolean(search) && (
              <Stack padding={1} direction="column" gutterSize={1}>
                <Dropzone
                  t={t}
                  triggerFileUpload={triggerFileUpload}
                  triggerFolderUpload={triggerFolderUpload}
                  onAdd={handleAddDocuments}
                  variant="dataTable"
                />
                <DocumentsCount gutterSize={0.5} alignY="center" marginTop={2}>
                  <span>{t('document_will_notify')}</span>
                </DocumentsCount>
              </Stack>
            )
          }
        />

        {Boolean(search) && (
          <DocumentsCount>
            {t('search_result', { count: searchFiles.length })}
          </DocumentsCount>
        )}

        <DocumentsCount gutterSize={0.5} alignY="center" marginTop={2}>
          <ShieldLock />
          <span>
            {t('document_count', { count: data?.work?.documents?.totalCount })}
          </span>
        </DocumentsCount>
      </Container>

      <Modal
        isOpen={Boolean(deletedFolder)}
        title={t('delete_folder_title')}
        onRequestClose={() => setDeletedFolder(null)}
      >
        <Stack direction="column" gutterSize={1}>
          <p>
            <Trans i18nKey="vault:delete_folder_description">
              Are you sure you want to delete the folder
              <strong>{{ folder: deletedFolder?.name }}</strong>?
              <br />
              This cannot be undone.
            </Trans>
          </p>

          <Stack alignX="center" size="full" gutterSize={1}>
            <Button
              onClick={handleDeleteFolder}
              variant="primary"
              isLoading={isDeletingFolder}
              autoFocus={true}
            >
              {t('delete_folder_confirm')}
            </Button>
            <Button variant="text" onClick={() => setDeletedFolder(null)}>
              {t('delete_folder_dismiss')}
            </Button>
          </Stack>
        </Stack>
      </Modal>
      <Modal
        isOpen={Boolean(showDeleteEntitiesModal)}
        title={t('delete_entities_title', { count: selectedEntitiesId.length })}
        onRequestClose={() => setShowDeleteEntitiesModal(false)}
      >
        <Stack direction="column" gutterSize={1}>
          <p>
            <Trans i18nKey="vault:delete_entities_description">
              Are you sure you want to delete the folder
              <strong>{{ folder: deletedFolder?.name }}</strong>?
              <br />
              This cannot be undone.
            </Trans>
          </p>

          <Stack alignX="center" size="full" gutterSize={1}>
            <Button
              onClick={handleDeleteEntities}
              variant="primary"
              isLoading={isDeletingEntities}
              autoFocus={true}
            >
              {t('delete_entities_confirm')}
            </Button>
            <Button
              variant="text"
              onClick={() => setShowDeleteEntitiesModal(false)}
            >
              {t('delete_entities_dismiss')}
            </Button>
          </Stack>
        </Stack>
      </Modal>
      <Modal
        isOpen={Boolean(showDownloadEntitiesModal)}
        title={t('download_entities_title', {
          count: selectedEntitiesId.length,
        })}
        onRequestClose={() => setShowDownloadEntitiesModal(false)}
      >
        <Stack direction="column" gutterSize={1}>
          <p>
            <Trans i18nKey="vault:download_entities_description">
              Are you sure you want to download the content of selected elements
              ?
            </Trans>
          </p>

          <Stack alignX="center" size="full" gutterSize={1}>
            <Button
              onClick={handleDownloadEntities}
              variant="primary"
              isLoading={isDownloadingEntities}
              autoFocus={true}
            >
              {t('download_entities_confirm')}
            </Button>
            <Button
              variant="text"
              onClick={() => setShowDownloadEntitiesModal(false)}
            >
              {t('download_entities_dismiss')}
            </Button>
          </Stack>
        </Stack>
      </Modal>

      <Modal
        isOpen={Boolean(deletedDocument)}
        title={t('delete_document_title')}
        onRequestClose={() => setDeletedDocument(null)}
      >
        <Stack direction="column" gutterSize={1}>
          <p>
            <Trans i18nKey="vault:delete_document_description">
              Are you sure you want to delete the folder
              <strong>{{ document: deletedDocument?.name }}</strong>?
              <br />
              This cannot be undone.
            </Trans>
          </p>

          <Stack alignX="center" size="full" gutterSize={1}>
            <Button
              onClick={handleDeleteDocument}
              variant="primary"
              isLoading={isDeletingDocument}
              autoFocus={true}
            >
              {t('delete_document_confirm')}
            </Button>
            <Button variant="text" onClick={() => setDeletedDocument(null)}>
              {t('delete_document_dismiss')}
            </Button>
          </Stack>
        </Stack>
      </Modal>

      <Modal
        isOpen={shownNewFolderModal}
        title={t('new_folder')}
        onRequestClose={() => setShownNewFolderModal(false)}
      >
        <Formik initialValues={{ name: '' }} onSubmit={handleCreateFolder}>
          {({ isSubmitting }) => (
            <Form>
              <TextField
                name="name"
                label={t('folder_name')}
                autoFocus={true}
              />
              <Buttons alignX="center">
                <Button
                  type="button"
                  onClick={() => setShownNewFolderModal(false)}
                >
                  {t('cancel')}
                </Button>
                <Button variant="primary" isLoading={isSubmitting}>
                  {t('save')}
                </Button>
              </Buttons>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const VaultWrapper = () => {
  const { workSlug } = useParams();

  const { data } = useQuery(GET_WORK_FOLDERS, {
    variables: pickBy({
      slug: workSlug,
    }),
  });

  if (!Boolean(data)) {
    return null;
  }

  return <Vault rootFolderSlug={data?.work?.folder?.slug} />;
};

export default VaultWrapper;
