import React from 'react';
import styled, { css } from 'styled-components';
import { Stack, ButtonReset, media } from '@tymate/margaret';
import Dotdotdot from 'react-dotdotdot';
import { CloudArrowDown, Eye, X } from 'react-bootstrap-icons';
import { getDocumentIconFromMimeType, isPreviewable } from 'utils';
import AttachmentViewStatus from './AttachmentViewStatus';

const Wrapper = styled(Stack)`
  flex-direction: column;
  align-items: start;

  > * + * {
    margin-left: 0;
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }

  ${media.tablet`
    flex-direction: row;
    align-items: center;

    > * + * {
      margin-top: 0;
      margin-left: ${({ theme }) => theme.spacing(0.5)};
    }
  `}
`;

const InputWrapper = styled(Stack)`
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadiusPill};
  background-color: #fff;
  width: 22em;
  max-width: 80vw;

  ${({ variant }) =>
    variant === 'rejected' &&
    css`
      background-color: ${({ theme }) => theme.errorBackground};
    `}
`;

const FileIcon = styled.span`
  color: ${({ theme }) => theme.primary};
  line-height: 1;
`;

const DownloadIcon = styled.span`
  color: ${({ theme }) => theme.tertiary};
  line-height: 1;
`;

const FileName = styled(Dotdotdot)``;

const Attachment = ({
  file,
  onRemove,
  onDownload,
  withUpload,
  withViewStatus,
}) => {
  return (
    <Wrapper gutterSize={0.5}>
      <InputWrapper
        paddingHorizontal={0.75}
        paddingVertical={0.5}
        alignX="space-between"
        variant={file?.scanStatus === 'REJECTED' && 'rejected'}
      >
        <Stack alignY="center" gutterSize={0.5}>
          <FileIcon>{getDocumentIconFromMimeType(file?.mimeType, 20)}</FileIcon>
          <FileName clamp={1}>{file?.name || file?.fileName}</FileName>
        </Stack>

        <Stack gutterSize={0.5}>
          {withUpload && isPreviewable(file) && (
            <DownloadIcon
              as={withUpload ? 'a' : null}
              href="#"
              rel="noopener noreferrer"
              onClick={() => {
                onDownload(file);

                const previewUrl = new URL(file.url);
                previewUrl.searchParams.append('preview', true);

                const newWindow = window.open(
                  previewUrl,
                  '_blank',
                  'noopener,noreferrer',
                );
                if (newWindow) newWindow.opener = null;
              }}
              onContextMenu={$event => {
                $event.preventDefault();
                onDownload(file);

                const previewUrl = new URL(file.url);
                previewUrl.searchParams.append('preview', true);

                const newWindow = window.open(
                  previewUrl,
                  '_blank',
                  'noopener,noreferrer',
                );
                if (newWindow) newWindow.opener = null;
              }}
            >
              <Eye size={22} />
            </DownloadIcon>
          )}

          {withUpload && (
            <DownloadIcon
              as={withUpload ? 'a' : null}
              href="#"
              rel="noopener noreferrer"
              onClick={() => {
                onDownload(file);
                window.open(file.url, '_self');
              }}
              onContextMenu={$event => {
                $event.preventDefault();
                onDownload(file);
                window.open(file.url, '_self');
              }}
            >
              <CloudArrowDown size={22} />
            </DownloadIcon>
          )}
          {onRemove && (
            <ButtonReset type="button" onClick={onRemove}>
              <X size={24} />
            </ButtonReset>
          )}
        </Stack>
      </InputWrapper>

      {withUpload && withViewStatus && (
        <Stack gutterSize={0.25}>
          <AttachmentViewStatus viewed={file?.viewed} />
        </Stack>
      )}
    </Wrapper>
  );
};

export default Attachment;
