import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormField, FormLabel } from 'ui/forms';
import { Form, TextField } from 'components/Forms';
import { Stack } from '@tymate/margaret';
import { CountryDropdown } from 'react-country-region-selector';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from 'components';

const Cell = styled.div``;

const BillingAccountAddressForm = ({
  billingAccount,
  onSubmit,
  onDismiss,
  variant,
}) => {
  const { t } = useTranslation(['invoices', 'errors']);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        addressLine1: billingAccount?.addressLine1 || '',
        addressLine2: billingAccount?.addressLine2 || '',
        addressPostalCode: billingAccount?.addressPostalCode || '',
        addressCity: billingAccount?.addressCity || '',
        addressState: billingAccount?.addressState || '',
        addressCountry: billingAccount?.addressCountry || '',
      }}
      validationSchema={Yup.object().shape({
        addressLine1: Yup.string().required(t('errors:required')),
        addressLine2: Yup.string(),
        addressPostalCode: Yup.string().required(t('errors:required')),
        addressCity: Yup.string().required(t('errors:required')),
        addressCountry: Yup.string().required(t('errors:required')),
      })}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form>
          <TextField
            name="addressLine1"
            label={t('address_line_1')}
            placeholder={t('address_line_1_placeholder')}
          />
          <TextField
            name="addressLine2"
            label={t('address_line_2')}
            placeholder={t('address_line_2_placeholder')}
          />
          <Stack
            direction={{ default: 'column', tablet: 'row' }}
            alignX={{ default: 'stretch' }}
            size="full"
            gutterSize={2}
            marginBottom={1.5}
          >
            <Cell style={{ flex: 1 }}>
              <TextField
                name="addressPostalCode"
                label={t('address_postal_code')}
                placeholder={t('address_postal_code_placeholder')}
              />
            </Cell>
            <Cell style={{ flex: 2 }}>
              <TextField name="addressCity" label={t('address_city')} />
            </Cell>

            <Cell style={{ flex: 2 }}>
              <FormField>
                <FormLabel>{t('address_country')}</FormLabel>
                <CountryDropdown
                  value={values.addressCountry}
                  valueType="short"
                  onChange={country => setFieldValue('addressCountry', country)}
                />
              </FormField>
            </Cell>
          </Stack>

          <Stack alignY="center">
            <Button
              isLoading={isSubmitting}
              variant="primary"
              size={variant === 'inline' ? 'small' : null}
            >
              {t('save')}
            </Button>
            <Button
              size={variant === 'inline' ? 'small' : null}
              onClick={onDismiss}
            >
              {t('cancel')}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default BillingAccountAddressForm;
