import React, { useState, useEffect } from 'react';
import { Buttons, ConfirmButton, Modal, Stack } from '@tymate/margaret';
import { useTranslation, Trans } from 'react-i18next';
import { get, omit } from 'lodash';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import {
  useParams,
  useNavigate,
  NavLink,
  Route,
  Routes,
} from 'react-router-dom';
import {
  Header,
  OrganizationForm,
  Button,
  Member,
  InvitationForm,
} from 'components';
import { Card, SubText, MembersList, Title, Container } from 'ui';
import { Tabs, Tab, TabNavButton } from 'ui/tabs';
import { useApp } from 'hooks';
import { CLIENT_ID } from '../../../constants';
import { PersonPlusFill } from 'react-bootstrap-icons';
import { useDebounce } from 'react-use';
import Search from 'components/Search';

export const GET_TENANT = gql`
  query getTenant($slug: String!) {
    tenant(slug: $slug) {
      id
      name
      logoUrl
      canChangeOwner {
        value
      }
      canDelete {
        value
      }
      canUpdate {
        value
      }
      members {
        edges {
          node {
            id
            role
            user {
              firstName
              lastName
              email
              avatarUrl
              id
            }
            invitation {
              id
              role
              invitedAt
              acceptedAt
            }
          }
        }
      }
      workContacts {
        edges {
          node {
            roles {
              nodes {
                work {
                  name
                }
              }
            }
            user {
              id
              avatarUrl
              firstName
              lastName
              email
            }
          }
        }
      }
      owner {
        id
        firstName
        lastName
        avatarUrl
        email
      }
      works {
        # TODO fetch totalCount for workCard
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

const UPDATE_TENANT = gql`
  mutation updateTenant($input: UpdateTenantInput!) {
    updateTenant(input: $input) {
      tenant {
        id
        slug
        name
        logoUrl
        owner {
          id
          firstName
          lastName
          avatarUrl
          email
        }
      }
    }
  }
`;

const DELETE_TENANT = gql`
  mutation deleteTenant($input: DeleteTenantInput!) {
    deleteTenant(input: $input) {
      tenant {
        id
      }
    }
  }
`;

const INVITE_TO_TENANT = gql`
  mutation inviteToTenant($input: InviteToTenantInput!) {
    inviteToTenant(input: $input) {
      tenant {
        id
      }
      invitation {
        id
      }
    }
  }
`;

const REVOKE_TENANT_ACCESS = gql`
  mutation revokeTenantAccess($input: RevokeTenantAccessInput!) {
    revokeTenantAccess(input: $input) {
      tenant {
        id
      }
      user {
        id
      }
    }
  }
`;

const RESEND_INVITATION = gql`
  mutation resendInvitation($input: ResendInvitationInput!) {
    resendInvitation(input: $input) {
      invitation {
        id
      }
    }
  }
`;

const Settings = () => {
  const { organizationSlug } = useParams();
  const { notify } = useApp();
  const { t } = useTranslation(
    'organizationSettings',
    'contacts',
    'invitations',
  );
  const navigate = useNavigate();
  const [roleInvitationModal, setRoleInvitationModal] = useState(false);

  const [contactSearch, setContactSearch] = useState('');
  const [debouncedContactSearch, setDebouncedContactSearch] = useState('');

  const [feedbackModalIsShown, setFeedbackModalIsShown] = useState(false);
  const { loading, data, refetch } = useQuery(GET_TENANT, {
    variables: { slug: organizationSlug },
  });
  const [updateTenant] = useMutation(UPDATE_TENANT);
  const [deleteTenant] = useMutation(DELETE_TENANT);
  const [inviteToTenant] = useMutation(INVITE_TO_TENANT, {
    refetchQueries: [
      {
        query: GET_TENANT,
        variables: { slug: organizationSlug },
      },
    ],
  });
  const [revokeTenantAccess] = useMutation(REVOKE_TENANT_ACCESS, {
    refetchQueries: [
      {
        query: GET_TENANT,
        variables: { slug: organizationSlug },
      },
    ],
  });
  const [resendInvitation] = useMutation(RESEND_INVITATION, {
    refetchQueries: [
      {
        query: GET_TENANT,
        variables: {
          slug: organizationSlug,
        },
      },
    ],
  });

  const handleDeleteTenant = async () => {
    try {
      await deleteTenant({
        variables: {
          input: {
            tenantId: data.tenant.id,
          },
        },
      });
      navigate('/organizations');
    } catch (err) {
      console.dir(err);
    }
  };

  const handleRevokeAccess = async userId => {
    try {
      await revokeTenantAccess({
        variables: {
          input: {
            tenantId: data.tenant.id,
            userId,
          },
        },
      });
      refetch();
      notify(t('delete_user_success'));
    } catch (err) {
      console.dir(err);
      notify(t('delete_user_error'), { type: 'error' });
    }
  };

  const handleSubmit = async values => {
    const payload =
      typeof values.logo === 'string' ? omit(values, 'logo') : values;
    await updateTenant({
      variables: {
        input: {
          tenantId: data.tenant.id,
          ...payload,
        },
      },
    });
  };

  const handleChangeOwner = async id => {
    try {
      await updateTenant({
        variables: {
          input: {
            tenantId: data.tenant.id,
            ownerId: id,
          },
        },
      });
      refetch();
    } catch (err) {
      console.dir(err);
    }
  };

  const handleInvitMember = async values => {
    let userExists = data?.tenant?.members?.edges.find(
      ({ node }) => node?.user?.email === values?.email,
    );
    if (userExists) {
      setFeedbackModalIsShown(true);
      return null;
    }
    try {
      await inviteToTenant({
        variables: {
          input: {
            clientId: CLIENT_ID,
            tenantId: data.tenant.id,
            role: roleInvitationModal,
            user: values,
          },
        },
      });
      refetch();
      setRoleInvitationModal();
    } catch (err) {
      console.dir(err);
    }
  };

  const handleResendInvitation = async invitationId => {
    try {
      await resendInvitation({
        variables: {
          input: {
            clientId: CLIENT_ID,
            invitationId: invitationId,
          },
        },
      });
      notify(t('invitations:resent'));
    } catch (err) {}
  };

  const hasDataButCanNotUpdate = data && !data?.tenant?.canUpdate?.value;
  useEffect(() => {
    if (hasDataButCanNotUpdate) {
      navigate(-1);
    }
  }, [hasDataButCanNotUpdate, navigate]);

  useDebounce(
    () => {
      setDebouncedContactSearch(contactSearch);
    },
    300,
    [contactSearch],
  );

  if (loading || !data) {
    return null;
  }

  const admins = get(data, 'tenant.members.edges', [])
    .filter(({ node }) => node.role === 'ADMIN')
    .filter(({ node }) => node.user.id !== data?.tenant?.owner?.id);

  const collaborators = get(data, 'tenant.members.edges', []).filter(
    ({ node }) => node.role === 'COLLABORATOR',
  );

  const contacts = get(data, 'tenant.workContacts.edges', []).filter(
    ({ node }) => {
      const searchExpression = debouncedContactSearch.toLowerCase();
      return (
        node.user.firstName.toLowerCase().includes(searchExpression) ||
        node.user.lastName.toLowerCase().includes(searchExpression) ||
        node.user.email.toLowerCase().includes(searchExpression)
      );
    },
  );

  return (
    <>
      <Header title={data?.tenant?.name} containerSize="narrow" />

      <Container variant="main" size="narrow">
        <Tabs>
          <Tab>
            <TabNavButton as={NavLink} to="">
              {t('informations')}
            </TabNavButton>
          </Tab>
          <Tab>
            <TabNavButton as={NavLink} to="collaborators">
              {t('collaborators')}
            </TabNavButton>
          </Tab>
          <Tab>
            <TabNavButton as={NavLink} to="contacts">
              {t('contacts')}
            </TabNavButton>
          </Tab>
        </Tabs>

        <Routes>
          <Route
            path=""
            element={
              <>
                <Card>
                  <OrganizationForm
                    onSubmit={handleSubmit}
                    tenant={data.tenant}
                  />
                </Card>
                <Card title={t('admins')}>
                  <MembersList>
                    <Member user={data?.tenant?.owner} role="OWNER" t={t} />
                    {admins.map(({ node }) => (
                      <Member
                        user={node.user}
                        onUpdateOwner={handleChangeOwner}
                        onRevokeAccess={handleRevokeAccess}
                        onResendInvitation={handleResendInvitation}
                        role="ADMIN"
                        invitation={node.invitation}
                        t={t}
                        key={node.id}
                        canChangeOwner={data?.tenant?.canChangeOwner?.value}
                      />
                    ))}
                  </MembersList>
                  <Buttons paddingTop={1}>
                    <Button
                      variant="primary"
                      icon={<PersonPlusFill />}
                      onClick={() => setRoleInvitationModal('ADMIN')}
                    >
                      {t('add_admin')}
                    </Button>
                  </Buttons>
                </Card>
                {data?.tenant?.canDelete?.value && (
                  <Card title={t('delete_organization_title')}>
                    <SubText>{t('delete_organization_explanation')}</SubText>
                    <Buttons>
                      <ConfirmButton
                        confirmElement={
                          <Button variant="outline" as="span">
                            {t('delete_organization')}
                          </Button>
                        }
                        modalTitle={t('delete_organization')}
                        modalContent={t('delete_organization_explanation')}
                        modalCancelTitle={t('cancel')}
                        modalSubmitTitle={t('delete')}
                        onSubmit={handleDeleteTenant}
                      />
                    </Buttons>
                  </Card>
                )}
              </>
            }
          />
          <Route
            path="collaborators"
            element={
              <Card title={t('collaborators')}>
                <SubText style={{ marginBottom: 0 }}>
                  {t('add_collaborator_explanation')}
                </SubText>
                <MembersList>
                  {collaborators.map(({ node }) => (
                    <Member
                      user={node.user}
                      role={'COLLABORATOR'}
                      invitation={node.invitation}
                      t={t}
                      key={node.id}
                      onRevokeAccess={handleRevokeAccess}
                      onResendInvitation={handleResendInvitation}
                    />
                  ))}
                </MembersList>
                <Buttons paddingTop={1}>
                  <Button
                    variant="primary"
                    icon={<PersonPlusFill />}
                    onClick={() => setRoleInvitationModal('COLLABORATOR')}
                  >
                    {t('add_collaborator')}
                  </Button>
                </Buttons>
              </Card>
            }
          />

          <Route
            path="contacts"
            element={
              <Card title={t('contacts')} style={{ overflow: 'visible' }}>
                <SubText style={{ marginTop: '0.25em', marginBottom: '1em' }}>
                  {t('contacts_explanation')}
                </SubText>
                <Search
                  value={contactSearch}
                  onChange={setContactSearch}
                  size="full"
                />
                <MembersList>
                  {contacts.map(({ node }) => (
                    <Member
                      user={node.user}
                      role={'CONTACT'}
                      t={t}
                      key={node.id}
                      shouldHideDeleteOption
                      workList={node?.roles?.nodes}
                    />
                  ))}
                  {contacts.length === 0 && (
                    <SubText style={{ marginTop: 0 }}>
                      {t('contacts:no_contacts_found')}
                    </SubText>
                  )}
                </MembersList>
              </Card>
            }
          />
        </Routes>
      </Container>

      <Modal
        size="medium"
        isOpen={roleInvitationModal}
        onRequestClose={() => setRoleInvitationModal()}
      >
        <Title>
          {roleInvitationModal === 'ADMIN'
            ? t('add_admin')
            : t('add_collaborator')}
        </Title>
        <SubText>
          <Trans
            i18nKey={
              roleInvitationModal === 'ADMIN'
                ? 'organizationSettings:admin_invitation_legend'
                : 'organizationSettings:collaborator_invitation_legend'
            }
          >
            The admin will be able to consult all{' '}
            <strong>{{ organizationName: data?.tenant?.name }}</strong>'s
            folders.
          </Trans>
        </SubText>
        <InvitationForm
          onSubmit={handleInvitMember}
          t={t}
          onCancel={() => setRoleInvitationModal()}
        />
      </Modal>
      <Modal
        isOpen={feedbackModalIsShown}
        onRequestClose={() => setFeedbackModalIsShown(false)}
      >
        <Stack direction="column">
          <Title>
            {roleInvitationModal === 'ADMIN'
              ? t('add_admin')
              : t('add_collaborator')}
          </Title>
          <SubText>{t('user_already_added')}</SubText>
          <Button
            variant="outline"
            onClick={() => setFeedbackModalIsShown(false)}
          >
            {t('back_to_adding')}
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default Settings;
