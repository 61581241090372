import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'hooks';

const Callback = () => {
  const { tenantSlug, workSlug, requestSlug, actionSlug } = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (Boolean(tenantSlug) && Boolean(workSlug) && Boolean(requestSlug)) {
      navigate(
        `/organizations/${tenantSlug}/${workSlug}/requests/${requestSlug}`,
      );
    } else if (Boolean(tenantSlug) && Boolean(workSlug)) {
      if (Boolean(actionSlug)) {
        switch (actionSlug) {
          case 'vault':
            navigate(`/organizations/${tenantSlug}/${workSlug}/vault`);
            break;
          default:
            navigate(`/organizations/${tenantSlug}/${workSlug}`);
            break;
        }
      } else {
        navigate(`/organizations/${tenantSlug}/${workSlug}`);
      }
    } else if (Boolean(tenantSlug)) {
      navigate(`/organizations/${tenantSlug}`);
    } else {
      navigate('/');
    }
    // eslint-disable-next-line
  }, []);

  return null;
};

export default Callback;
