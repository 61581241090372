import React, { useEffect, useState } from 'react';
import { STRIPE_API_KEY /*INCLUDED_FREEMIUM_TOKENS*/ } from '../../constants';
import { Container as RawContainer, Card, fontStyles } from 'ui';
import { useApp } from 'hooks';
import styled, { css } from 'styled-components';
import { Spinner, Stack } from '@tymate/margaret';
import { useTranslation, Trans } from 'react-i18next';
import { Button } from 'components';
import { formatCurrency, formatDate } from 'utils';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCardInput from 'components/StripeCardInput';
import Offers from 'components/Offers';
import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import SubscriptionConfirmation from 'components/SubscriptionConfirmation';
import BillingAccountAddressForm from 'components/BillingAccountAddressForm';
import { Tabs, Tab, TabNavButton } from 'ui/tabs';
import { CURRENT_USER } from 'containers/AppProvider';
import { flatten, find, isNull, orderBy } from 'lodash';
import { CloudArrowDown } from 'react-bootstrap-icons';
import yousignLogo from 'images/yousign-logo.png';

const Container = styled(RawContainer)`
  select {
    display: block;
    width: 100%;
    padding: ${({ theme }) => theme.spacing(0.5)}
      ${({ theme }) => theme.spacing(0.75)};
    border: 1px solid ${({ theme }) => theme.separator};
    border-radius: 4px;
    background-position: calc(100% - 16px);

    &,
    &:focus {
      outline: none;
    }
  }
`;

const Title = styled.h2`
  margin-top: 0;
  font-size: 1.25rem;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

const DownloadButton = styled(Button)`
  padding: 0;
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
  display: flex;

  > svg {
    display: flex;
  }
`;

const Subtitle = styled.p`
  ${fontStyles.body}
  margin-top: 0;
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

const PriceName = styled.div`
  ${fontStyles.bodyLarge}
  font-weight: bold;
`;

const PriceBox = styled(Stack)`
  font-weight: bold;
  font-size: 1.5rem;
`;

const CurrentProduct = styled(Stack)`
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.separator};
  flex: 1;
`;

const CurrentTimeRange = styled.div`
  font-weight: 600;
`;

const Dt = styled.dt`
  ${fontStyles.bodySmall}
`;

const Amount = styled.dd`
  ${fontStyles.bodySmall}
  color: ${({ theme }) => theme.textLighter};

  span {
    color: ${({ theme }) => theme.text};
    font-weight: 600;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.primaryLightish};
  position: relative;
  ${({ amount }) =>
    amount &&
    css`
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        height: 5px;
        border-radius: 0 3px 3px 0;
        width: ${({ amount }) => amount}%;
        max-width: 100%;
        background-color: ${({ theme }) => theme.primary};
      }
    `}
`;

const Label = styled.p`
  ${fontStyles.bodySmall}

  margin: 0;
  color: ${({ theme }) => theme.textLight};

  span {
    color: ${({ theme }) => theme.text};
    font-weight: 600;
  }
`;

const YousignLogo = styled.img`
  max-width: 1.5em;
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

const STRIPE_PRODUCTS = gql`
  query stripeProducts {
    stripeProducts {
      code
      id
      name
      visible
      vault
      wrapperApi
      signature
      stripeSignaturePriceId
      stripeSignatureProduct {
        code
        id
        name
        prices {
          id
          unitAmount
        }
      }
      prices {
        currency
        id
        recurrence {
          interval
          intervalCount
        }
        tiers {
          flatAmount
          unitAmount
          upTo
        }
        tiersMode
        type
        unitAmount
      }
    }
  }
`;

const UPDATE_BILLING_ACCOUNT = gql`
  mutation updateBillingAccount($input: UpdateBillingAccountInput!) {
    updateBillingAccount(input: $input) {
      billingAccount {
        id
        kind
        addressLine1
        addressLine2
        addressPostalCode
        addressCity
        addressCountry
        addressState
        stripePaymentMethodId
      }
    }
  }
`;

const CREATE_BILLING_ACCOUNT = gql`
  mutation createBillingAccount($input: CreateBillingAccountInput!) {
    createBillingAccount(input: $input) {
      billingAccount {
        id
        kind
        addressLine1
        addressLine2
        addressPostalCode
        addressCity
        addressCountry
        addressState
        stripePaymentMethodId
      }
      errors {
        path
        message
      }
    }
  }
`;

const UPDATE_PAYMENT_METHOD = gql`
  mutation updatePaymentMethod($input: UpdatePaymentMethodInput!) {
    updatePaymentMethod(input: $input) {
      billingAccount {
        id
        kind
        paymentMethodLast4
        paymentMethodName
        stripePaymentMethodId
      }
    }
  }
`;

const UNSUBSCRIBE_BILLING_ACCOUNT = gql`
  mutation unsubscribeBillingAccount($input: UnsubscribeBillingAccountInput!) {
    unsubscribeBillingAccount(input: $input) {
      billingAccount {
        id
        currentUsage
        currentSignatureUsage
        kind
        subscriptionStatus
        subscriptionStatus
        stripePriceId
        stripePaymentMethodId
        stripeProduct {
          code
          id
          name
          prices {
            currency
            id
            recurrence {
              interval
              intervalCount
              usageType
            }
            tiers {
              flatAmount
              unitAmount
              upTo
            }
            tiersMode
            type
            unitAmount
          }
          visible
          stripeSignaturePriceId
          stripeSignatureProduct {
            code
            id
            name
            prices {
              currency
              id
              type
              unitAmount
            }
            visible
          }
        }
        customYousign
        customYousignUsage
      }
    }
  }
`;

const SUBSCRIBE_BILLING_ACCOUNT = gql`
  mutation subscribeBillingAccount($input: SubscribeBillingAccountInput!) {
    subscribeBillingAccount(input: $input) {
      billingAccount {
        id
        currentUsage
        currentSignatureUsage
        kind
        subscriptionStatus
        subscriptionStatus
        stripePriceId
        stripeProduct {
          code
          id
          name
          prices {
            currency
            id
            recurrence {
              interval
              intervalCount
              usageType
            }
            tiers {
              flatAmount
              unitAmount
              upTo
            }
            tiersMode
            type
            unitAmount
          }
          visible
          stripeSignaturePriceId
          stripeSignatureProduct {
            code
            id
            name
            prices {
              currency
              id
              type
              unitAmount
            }
            visible
          }
        }
      }
    }
  }
`;

const GET_USER_BILLING_ACCOUNT = gql`
  query Invoices_currentUserBillingAccount {
    currentUser {
      id
      billingAccount {
        id
        currentUsage
        currentSignatureUsage
        addressLine1
        addressLine2
        addressPostalCode
        addressCity
        addressCountry
        addressState
        email
        firstName
        lastName
        kind
        subscriptionStatus
        paymentMethodLast4
        paymentMethodName
        stripePaymentMethodId
        subscriptionStatus
        stripePriceId
        stripeProduct {
          code
          id
          name
          signature
          prices {
            currency
            id
            recurrence {
              interval
              intervalCount
              usageType
            }
            tiers {
              flatAmount
              unitAmount
              upTo
            }
            tiersMode
            type
            unitAmount
          }
          visible
          stripeSignaturePriceId
          stripeSignatureProduct {
            code
            id
            name
            prices {
              currency
              id
              type
              unitAmount
            }
            visible
          }
        }
        customYousign
        customYousignUsage
        invoices {
          id
          currency
          description
          hostedInvoiceUrl
          id
          invoicePdf
          paid
          periodEnd
          periodStart
          status
          total
        }
        upcomingInvoice {
          currency
          description
          hostedInvoiceUrl
          id
          invoicePdf
          paid
          periodEnd
          periodStart
          total
        }
      }
    }
  }
`;

const UPDATE_BILLING_ACCOUNT_SUBSCRIPTION = gql`
  mutation updateBillingAccountSubscription(
    $input: UpdateBillingAccountSubscriptionInput!
  ) {
    updateBillingAccountSubscription(input: $input) {
      billingAccount {
        id
        currentUsage
        currentSignatureUsage
        kind
        subscriptionStatus
        subscriptionStatus
        stripePriceId
        stripeProduct {
          code
          id
          name
          prices {
            currency
            id
            recurrence {
              interval
              intervalCount
              usageType
            }
            tiers {
              flatAmount
              unitAmount
              upTo
            }
            tiersMode
            type
            unitAmount
          }
          visible
          stripeSignaturePriceId
          stripeSignatureProduct {
            code
            id
            name
            prices {
              currency
              id
              type
              unitAmount
            }
            visible
          }
        }
        customYousign
        customYousignUsage
      }
    }
  }
`;

const Steps = ({ currentStep }) => {
  const { t } = useTranslation('invoices');

  return (
    <Tabs hasNoPadding>
      <Tab>
        <TabNavButton isActive>{t('activate_step_one')}</TabNavButton>
      </Tab>
      <Tab>
        <TabNavButton isActive={currentStep === 2}>
          {t('activate_step_two')}
        </TabNavButton>
      </Tab>
    </Tabs>
  );
};

const Invoices = () => {
  const { user, notify } = useApp();

  const { t } = useTranslation(['invoices', 'errors']);
  const stripePromise = loadStripe(STRIPE_API_KEY);

  const {
    data: currentBillingAccountData,
    refetch: refetchUserBillingAccount,
  } = useQuery(GET_USER_BILLING_ACCOUNT, {
    onCompleted: () => setHasLoadedBillingAccount(true),
  });

  const { data } = useQuery(STRIPE_PRODUCTS, {
    onCompleted: () => setHasLoadedStripeProducts(true),
  });
  const [updatePaymentMethod] = useMutation(UPDATE_PAYMENT_METHOD, {
    refetchQueries: [{ query: CURRENT_USER }],
    awaitRefetchQueries: true,
  });
  const [updateBillingAccount] = useMutation(UPDATE_BILLING_ACCOUNT, {
    refetchQueries: [{ query: CURRENT_USER }],
    awaitRefetchQueries: true,
  });
  const [
    createBillingAccount,
    { data: createbillingAccountData },
  ] = useMutation(CREATE_BILLING_ACCOUNT, {
    refetchQueries: [{ query: CURRENT_USER }],
    awaitRefetchQueries: true,
  });
  const [unsubscribeBillingAccount, { loading: isUnsubscribing }] = useMutation(
    UNSUBSCRIBE_BILLING_ACCOUNT,
    {
      onCompleted: () => {
        refetchUserBillingAccount();
      },
    },
  );
  const [subscribeBillingAccount, { loading: isSubscribing }] = useMutation(
    SUBSCRIBE_BILLING_ACCOUNT,
    {
      onCompleted: () => {
        refetchUserBillingAccount();
      },
    },
  );
  const [
    updateBillingAccountSubscription,
    { loading: isUpdatingSubscription },
  ] = useMutation(UPDATE_BILLING_ACCOUNT_SUBSCRIPTION);

  const billingAccount =
    currentBillingAccountData?.currentUser?.billingAccount ||
    createbillingAccountData?.createBillingAccount?.billingAccount;
  const hasBillingAccount = Boolean(
    currentBillingAccountData?.currentUser?.billingAccount,
  );
  const kind = billingAccount?.kind;
  const price = find(
    billingAccount?.stripeProduct?.prices || [],
    ({ id }) => id === billingAccount?.stripePriceId,
  );
  const signaturePrice = find(
    billingAccount?.stripeProduct?.stripeSignatureProduct?.prices || [],
    ({ id }) => id === billingAccount?.stripeProduct?.stripeSignaturePriceId,
  );

  const [step, setStep] = useState(Boolean(billingAccount) ? 'info' : 'plans');
  const [stagedPricing, setStagedPricing] = useState();
  const [hasLoadedStripeProducts, setHasLoadedStripeProducts] = useState();
  const [hasLoadedBillingAccount, setHasLoadedBillingAccount] = useState();

  const [shouldShowCardUpdate, setShouldShowCardUpdate] = useState(false);
  const [shouldShowAddressUpdate, setShouldShowAddressUpdate] = useState(false);

  const stagedPricingFlatAmount = orderBy(
    find(
      flatten((data?.stripeProducts ?? []).map(({ prices }) => prices)),
      ({ id }) => id === stagedPricing,
    )?.tiers,
    ({ flatAmount }) => -1 * flatAmount,
  )?.[0]?.flatAmount;

  const basicPricing = (price?.tiers || []).find(({ upTo }) => Boolean(upTo));

  const handleSelectOffer = async (
    priceIdOrKind,
    paymentMethodId,
    billingAccountId,
  ) => {
    if (stagedPricing !== priceIdOrKind) {
      setStagedPricing(priceIdOrKind);
    }

    try {
      if (!billingAccount?.stripePaymentMethodId && !paymentMethodId) {
        setStep('activateInfo');
        return;
      }

      if (priceIdOrKind === 'FREEMIUM') {
        await unsubscribeBillingAccount({
          variables: {
            input: {
              billingAccountId: Boolean(billingAccountId)
                ? billingAccountId
                : billingAccount?.id,
            },
          },
        });
        return;
      }

      if (Boolean(billingAccount?.stripeProduct)) {
        await updateBillingAccountSubscription({
          variables: {
            input: {
              billingAccountId: Boolean(billingAccountId)
                ? billingAccountId
                : billingAccount?.id,
              priceId: priceIdOrKind,
            },
          },
        });
        setStep('confirmation');
        return;
      }

      await subscribeBillingAccount({
        variables: {
          input: {
            billingAccountId: Boolean(billingAccountId)
              ? billingAccountId
              : billingAccount?.id,
            paymentMethodId:
              paymentMethodId || billingAccount?.stripePaymentMethodId,
            priceId: priceIdOrKind,
          },
        },
      });
      setStep('confirmation');
      return;
    } catch (err) {
      notify(t('errors:select_offer_failed'), { type: 'error' });
    }
  };

  const handleUpdateBillingAccount = async values => {
    try {
      await updateBillingAccount({
        variables: {
          input: { ...values, billingAccountId: billingAccount.id },
        },
      });
      setShouldShowAddressUpdate(false);
    } catch (err) {}
  };

  const handleCreateBillingAccount = async (values, formikBag) => {
    try {
      if (Boolean(billingAccount)) {
        await handleUpdateBillingAccount(values, formikBag);
        if (stagedPricing === 'FREEMIUM') {
          setStep('info');
        } else {
          setStep('activatePayment');
        }
        return;
      }

      const { email, firstName, lastName } = user;
      const input = {
        ...values,
        email,
        firstName,
        lastName,
        addressState: 'Nord',
      };

      await createBillingAccount({ variables: { input } });
      if (stagedPricing === 'FREEMIUM') {
        setStep('info');
      } else {
        setStep('activatePayment');
      }
    } catch (err) {}
  };

  const handleUpdatePaymentMethod = async (
    paymentMethodId,
    billingAccountId,
  ) => {
    try {
      const newPaymentMethod = await updatePaymentMethod({
        variables: {
          input: {
            billingAccountId: Boolean(billingAccountId)
              ? billingAccountId
              : billingAccount?.id,
            paymentMethodId,
          },
        },
      });
      setShouldShowCardUpdate(false);
      return newPaymentMethod;
    } catch (err) {}
  };

  const handleCreatePaymentMethod = async paymentMethodId => {
    try {
      const { data } = await handleUpdatePaymentMethod(
        paymentMethodId,
        billingAccount?.id,
      );

      if (Boolean(stagedPricing)) {
        await handleSelectOffer(
          stagedPricing,
          data?.updatePaymentMethod?.billingAccount?.stripePaymentMethodId,
          billingAccount?.id,
        );
        setStagedPricing(null);
        setStep('confirmation');
      } else {
        setStep('info');
        notify(t('errors:generalError'), { type: 'error' });
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (hasLoadedBillingAccount) {
      setStep(hasBillingAccount ? 'info' : 'plans');
    }
  }, [hasLoadedBillingAccount, hasBillingAccount]);

  if (!Boolean(user) || !hasLoadedStripeProducts || !hasLoadedBillingAccount) {
    return <Spinner />;
  }

  return (
    <>
      {step === 'activateInfo' && (
        <Container variant="main" size="narrow">
          <Card>
            <Stack direction="column" gutterSize={1}>
              {stagedPricing !== 'FREEMIUM' && <Steps currentStep={1} />}
              <BillingAccountAddressForm
                billingAccount={billingAccount}
                onDismiss={() => setStep('plans')}
                onSubmit={handleCreateBillingAccount}
              />
            </Stack>
          </Card>
        </Container>
      )}

      {step === 'activatePayment' && (
        <Elements stripe={stripePromise}>
          <Container variant="main" size="narrow">
            <Card>
              <Stack direction="column" gutterSize={1}>
                {stagedPricing !== 'FREEMIUM' && <Steps currentStep={2} />}
                <StripeCardInput
                  billingAccount={billingAccount}
                  onUpdatePaymentMethod={handleCreatePaymentMethod}
                  onCancel={() => setStep('plans')}
                  targetPricing={stagedPricingFlatAmount}
                />
              </Stack>
            </Card>
          </Container>
        </Elements>
      )}

      {step === 'plans' && (
        <Container variant="main">
          <Title style={{ marginBottom: 16 }}>{t('plans_title')}</Title>
          <Offers
            stripeProducts={data?.stripeProducts}
            currentProduct={billingAccount?.stripeProduct}
            onSelect={handleSelectOffer}
            isUpdatingCurrentOffer={
              isSubscribing || isUpdatingSubscription || isUnsubscribing
            }
            currentBillingAccount={billingAccount}
          />
          {Boolean(billingAccount) && (
            <Stack alignX="center" marginTop={1.5}>
              <Button variant="primary" onClick={() => setStep('info')}>
                {t('back_to_info')}
              </Button>
            </Stack>
          )}
        </Container>
      )}

      {step === 'info' && (
        <Elements stripe={stripePromise}>
          <Container size="narrow" variant="main">
            <Card>
              <Stack direction="column" gutterSize={1} marginBottom={2}>
                <Title>{t('subscription')}</Title>
                <Stack size="full" gutterSize={1}>
                  <CurrentProduct gutterSize={1} padding={1} direction="column">
                    <Stack gutterSize={0.5} direction="column">
                      {kind === 'PAID' && (
                        <PriceName>
                          {billingAccount?.stripeProduct?.name}
                        </PriceName>
                      )}
                      <PriceBox>
                        {kind === 'FREE'
                          ? t('free')
                          : `${formatCurrency(basicPricing?.flatAmount || 0, {
                              currency: price?.currency,
                              stripDecimals: true,
                            })}/${t(
                              price?.recurrence?.interval.toLowerCase(),
                            )}`}
                      </PriceBox>
                    </Stack>
                    <Stack direction="column">
                      {kind === 'FREE' && (
                        <Stack>
                          <Label>
                            {t('up_to')} : <span>10</span>
                          </Label>
                        </Stack>
                      )}

                      {kind !== 'FREE' &&
                        (price?.tiers || []).map(
                          ({ unitAmount, upTo }, index) => (
                            <Stack key={index}>
                              {Boolean(upTo) && (
                                <Label>
                                  {t('up_to')} : <span>{upTo}</span>
                                </Label>
                              )}
                              {Boolean(unitAmount) && (
                                <Label>
                                  {t('unit_amount')} :{' '}
                                  <span>{formatCurrency(unitAmount)}</span>
                                </Label>
                              )}
                            </Stack>
                          ),
                        )}

                      {Boolean(billingAccount?.stripeProduct?.signature) &&
                        !Boolean(billingAccount?.customYousign) && (
                          <Label>
                            {t('signature_unit_amount')} :{' '}
                            <span>
                              {formatCurrency(signaturePrice?.unitAmount)}
                            </span>
                          </Label>
                        )}
                    </Stack>
                    <Button
                      variant="outline"
                      size="small"
                      onClick={() => setStep('plans')}
                    >
                      {t('update_billing_kind')}
                    </Button>
                  </CurrentProduct>
                  <CurrentProduct padding={1} direction="column" gutterSize={1}>
                    {Boolean(billingAccount?.upcomingInvoice?.periodStart) && (
                      <CurrentTimeRange>
                        {t('current_time_range', {
                          start:
                            Boolean(
                              billingAccount?.upcomingInvoice?.periodStart,
                            ) &&
                            formatDate(
                              billingAccount?.upcomingInvoice?.periodStart *
                                1000,
                              'dd/MM',
                            ),
                          end:
                            Boolean(
                              billingAccount?.upcomingInvoice?.periodEnd,
                            ) &&
                            formatDate(
                              billingAccount?.upcomingInvoice?.periodEnd * 1000,
                              'dd/MM',
                            ),
                        })}
                      </CurrentTimeRange>
                    )}
                    <Stack
                      as="dl"
                      gutterSize={0.5}
                      direction="column"
                      size="full"
                    >
                      <Stack alignX="space-between" size="full">
                        <Dt>{t('included_tokens')}</Dt>
                        <Amount>
                          <span>
                            {billingAccount?.currentUsage > basicPricing?.upTo
                              ? basicPricing?.upTo
                              : billingAccount?.currentUsage}
                          </span>{' '}
                          / {kind === 'FREE' ? '10' : basicPricing?.upTo}
                        </Amount>
                      </Stack>
                      <ProgressBar
                        amount={
                          kind === 'FREE'
                            ? (billingAccount?.currentUsage / 10) * 100
                            : billingAccount?.currentUsage > basicPricing?.upTo
                            ? '100%'
                            : (billingAccount?.currentUsage /
                                basicPricing?.upTo) *
                              100
                        }
                      />

                      {billingAccount?.currentUsage > basicPricing?.upTo && (
                        <>
                          <Stack alignX="space-between" size="full">
                            <Dt>{t('added_tokens')}</Dt>
                            <Amount>
                              <span>
                                {billingAccount?.currentUsage -
                                  basicPricing?.upTo}
                              </span>
                            </Amount>
                          </Stack>
                          <Stack alignX="space-between" size="full">
                            <Dt>{t('out_of_package_cost')}</Dt>
                            <Amount>
                              <span>
                                {formatCurrency(
                                  (billingAccount?.currentUsage -
                                    basicPricing?.upTo) *
                                    price?.tiers[1]?.unitAmount,
                                )}
                              </span>
                            </Amount>
                          </Stack>
                        </>
                      )}
                      {billingAccount?.stripeProduct?.signature &&
                        !Boolean(billingAccount?.customYousign) &&
                        !isNull(billingAccount?.currentSignatureUsage) && (
                          <>
                            <Stack alignX="space-between" size="full">
                              <Dt>{t('signatures_count')}</Dt>
                              <Amount>
                                <span>
                                  {billingAccount?.currentSignatureUsage}
                                </span>
                              </Amount>
                            </Stack>
                            <Stack alignX="space-between" size="full">
                              <Dt>{t('signatures_cost')}</Dt>
                              <Amount>
                                <span>
                                  {formatCurrency(
                                    billingAccount?.currentSignatureUsage *
                                      signaturePrice?.unitAmount,
                                  )}
                                </span>
                              </Amount>
                            </Stack>
                          </>
                        )}
                    </Stack>
                  </CurrentProduct>
                </Stack>

                {Boolean(billingAccount?.upcomingInvoice) && (
                  <div>
                    <Trans i18nKey="invoices:next_billing">
                      Your next invoice total will be{' '}
                      <strong>
                        {{
                          total: formatCurrency(
                            billingAccount?.upcomingInvoice?.total,
                          ),
                        }}
                      </strong>
                      . It will be issued on{' '}
                      <strong>
                        {{
                          date: formatDate(
                            billingAccount?.upcomingInvoice?.periodEnd * 1000,

                            'dd/MM/yyyy',
                          ),
                        }}
                      </strong>
                      .
                    </Trans>
                  </div>
                )}
              </Stack>

              {!kind ||
                (kind !== 'FREE' && billingAccount?.customYousign && (
                  <Stack direction="column" gutterSize={1} marginBottom={2}>
                    <Title>
                      {t('custom_yousign')}
                      <YousignLogo src={yousignLogo} />
                    </Title>

                    <Stack direction="column" gutterSize={1}>
                      <div>{t('custom_yousign_description')}</div>
                      <div>
                        <strong>{t('custom_yousign_usage')} : </strong>
                        {billingAccount?.customYousignUsage}
                      </div>
                    </Stack>
                  </Stack>
                ))}

              {!kind ||
                (kind !== 'FREE' && (
                  <Stack direction="column" gutterSize={1} marginBottom={2}>
                    <Title>{t('payment_method')}</Title>
                    {shouldShowCardUpdate ? (
                      <StripeCardInput
                        billingAccount={billingAccount}
                        onUpdatePaymentMethod={handleUpdatePaymentMethod}
                        onCancel={() => setShouldShowCardUpdate(false)}
                        variant="inline"
                      />
                    ) : (
                      <Stack direction="column" gutterSize={1}>
                        <div>
                          <Subtitle>
                            {billingAccount?.paymentMethodName}
                          </Subtitle>
                          **** **** **** {billingAccount?.paymentMethodLast4}
                        </div>
                        <Button
                          variant="outline"
                          size="small"
                          onClick={() => setShouldShowCardUpdate(true)}
                        >
                          {t('update_credit_card')}
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                ))}

              <Stack direction="column" marginBottom={2} gutterSize={1}>
                <Title>{t('information')}</Title>
                {shouldShowAddressUpdate ? (
                  <BillingAccountAddressForm
                    billingAccount={billingAccount}
                    onDismiss={() => setShouldShowAddressUpdate(false)}
                    onSubmit={handleUpdateBillingAccount}
                    variant="inline"
                  />
                ) : billingAccount?.addressLine1 ||
                  billingAccount?.addressCity ? (
                  <Stack direction="column" gutterSize={1}>
                    <div>
                      <div>{billingAccount?.addressLine1}</div>
                      <div>{billingAccount?.addressLine2}</div>
                      <div>
                        {billingAccount?.addressPostalCode}{' '}
                        {billingAccount?.addressCity},{' '}
                        {billingAccount?.addressState},{' '}
                        {billingAccount?.addressCountry}
                      </div>
                    </div>
                    <Button
                      variant="outline"
                      size="small"
                      onClick={() => setShouldShowAddressUpdate(true)}
                    >
                      {t('edit')}
                    </Button>
                  </Stack>
                ) : (
                  <Button
                    variant="outline"
                    size="small"
                    onClick={() => setShouldShowAddressUpdate(true)}
                  >
                    {t('add_invoices_information')}
                  </Button>
                )}
              </Stack>

              <Stack direction="column" gutterSize={1} size="full">
                <Title>{t('invoices')}</Title>
                <Stack size="full" direction="column" gutterSize={0.5}>
                  {Boolean(billingAccount?.invoices) &&
                  billingAccount.invoices.length >= 1 ? (
                    (billingAccount.invoices || []).map(
                      ({
                        id,
                        currency,
                        total,
                        periodStart,
                        periodEnd,
                        invoicePdf,
                      }) => (
                        <Stack
                          key={id}
                          size="full"
                          alignX="space-between"
                          gutterSize={0.5}
                          alignY="center"
                        >
                          <div>
                            {formatDate(periodStart * 1000, 'dd/MM/yyyy')} –{' '}
                            {formatDate(periodEnd * 1000, 'dd/MM/yyyy')}
                          </div>

                          <Stack gutterSize={0.5} alignY="center">
                            <span>{formatCurrency(total, { currency })}</span>
                            <DownloadButton
                              as="a"
                              href={invoicePdf}
                              variant="outline"
                              target="blank"
                            >
                              <CloudArrowDown size={20} />
                            </DownloadButton>
                          </Stack>
                        </Stack>
                      ),
                    )
                  ) : (
                    <div>{t('no_invoices')}</div>
                  )}
                </Stack>
              </Stack>
            </Card>
          </Container>
        </Elements>
      )}

      {step === 'confirmation' && (
        <SubscriptionConfirmation
          product={billingAccount?.stripeProduct}
          onDismiss={() => setStep('info')}
        />
      )}
    </>
  );
};

export default Invoices;
